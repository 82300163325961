import { ADD_TO_PAYOUT, CLEAR_PAYOUT } from "./actionsTypes";

const initialState = {
    payouts: {}
};
const Payout = (state = initialState , action: any) => {
    const {payload, type} = action;
    switch(type) {
        case ADD_TO_PAYOUT: {
            const payouts:any = {...state.payouts, payload};
            state = {
                ...state,
                payouts
            };
        }
            break;
        case CLEAR_PAYOUT: {
            const payouts:any = {};
            state = {
                ...state,
                payouts
            };
        }
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};
export default Payout;
