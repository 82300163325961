import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR, FORGET_PASSWORD_SUCCESS } from "./actionsTypes"

export const  userForgetPassword = (user: string , history: any , navigate : any) => {
    return {
        type: FORGET_PASSWORD,
        payload: {user , history, navigate}
    }
};
export const userForgetPasswordSuccess = (message: string) => {
    return{
        type: FORGET_PASSWORD_SUCCESS,
        payload: message
    }
};
export const userForgetPasswordError = (message: string) => {
    return {
        type: FORGET_PASSWORD_ERROR,
        payload: message
    }
};