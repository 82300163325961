import { Button } from 'react-bootstrap';
import { useState, useEffect , FormEvent} from 'react';
import { useTranslation } from 'react-i18next';

const OTPTimer = ( props:any ) => {
  const { initMinutes, initSeconds, resend, buttonText } = props;
  const [minutes, setMinutes] = useState<number>(initMinutes);
  const [seconds, setSeconds] = useState<number>(initSeconds);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = (e:FormEvent<EventTarget>) => {
    setMinutes(initMinutes);
    setSeconds(initSeconds);
    resend(e);
  };

  return (
      <div className="d-flex justify-content-between align-item-center pt-3">
        {seconds > 0 || minutes > 0 ? (
            <span>
          {t('form.otp.time-remaining')} {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
        </span>
        ) : (
            <span>{t('didnot_recieve_code?')}</span>
        )}

        <Button disabled={seconds > 0 || minutes > 0} className="round-btn" variant="success" size="sm" onClick={(e) => resendOTP(e)} > {buttonText} </Button>
      </div>
  );
}

export default OTPTimer;