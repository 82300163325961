import { call, put, takeEvery } from "redux-saga/effects";
import { resetPwdSuccess } from "./actions";
import { RESET_PASSWORD } from "./actionsTypes";
import { resetPwdApi } from "./apiCalls";

function* resetPassword({payload: {email , token,password, navigate}}: any):any{
    try{
        const response = yield call(resetPwdApi , {
            userId: email,
            userToken: token,
            Password: password
         });
         console.log(response);
         yield put(resetPwdSuccess(response.message));
         navigate("/login");
    }catch(error:any){
     console.log(error);
    }
   
   
}
function* authResetSaga(){
    yield takeEvery(RESET_PASSWORD, resetPassword);
}
export default authResetSaga;