/* eslint-disable no-lone-blocks */
import { ADD_TO_REQUEST, SELECT_REQUEST, UPDATE_SELECT_REQUEST,
    CLEAR_SELECT_REQUEST, REMOVE_FROM_REQUEST, SET_PARTNER_DATA,
    UPDATE_REQUEST, IS_READ_CONDITION, REQUEST_UID, RESET_REQUESTS } from "./actionsTypes";
import { v1 as uuidv1 } from 'uuid';

const initialState = {
    requests: [],
    selectRequest: {},
    uid: uuidv1(),
    isReadCondition: false,
    partnerInfo: {}
};

const Request = (state = initialState , action: any) => {
    const {payload, type} = action;
    switch(type) {
        case IS_READ_CONDITION:
            state = {
                ...state,
                isReadCondition: payload,
            };
            break;
        case ADD_TO_REQUEST: {
            const requests:any = [...state.requests, payload];
            state = {
                ...state,
                requests
            };
        }
            break;
        case UPDATE_REQUEST: {
            const requests:any = payload;
            state = {
                ...state,
                requests
            };
        }
            break;
        case REMOVE_FROM_REQUEST: {
            const requests:any = state.requests.filter((article:any)=> article?.articleUid !== payload);
            state = {
                ...state,
                requests
            };
        }
            break;
        case SELECT_REQUEST: {
            state = {
                ...state,
                selectRequest: payload
            };
        }
            break;
        case REQUEST_UID: {
            state = {
                ...state,
                uid: payload
            };
        }
            break;
        case UPDATE_SELECT_REQUEST: {
            const selectRequest:any = {...state.selectRequest, ...payload};
            state = {
                ...state,
                selectRequest
            };
        }
            break;
        case CLEAR_SELECT_REQUEST: {
            const selectRequest:any = null;
            state = {
                ...state,
                selectRequest
            };
        }
            break;
        case SET_PARTNER_DATA: {
            state = {
                ...state,
                partnerInfo: payload
            };
        }
            break
        case RESET_REQUESTS: {
            state = {
                ...state,
                requests: []
            };
        }
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
export default Request;
