/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Container, Card, Button, Form, Col, Row, InputGroup} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// Start import helpers
import { updateRequest } from "store/request/actions";
import { mapArrayToDropdown, majorCategoriesImg, actionConfirm, getPrefixUrl, getImagePlaceholder, concateToGetFileName, getImagePlaceholderForEditArticlePage } from 'helpers/common_helper';
import { toastValues } from 'common/toastValues';
import { del, put, get, post } from 'helpers/api_helpers';
import { BRAND_API} from 'constants/apiEndPoints/brandsEndpoints';
import { CATEGORY_API } from 'constants/apiEndPoints/categoriesEndpoints';
import {
    parentCategoryMale,
    parentCategoryFemale,
    photoPlaceholderFront,
    photoPlaceholderBack,
    photoPlaceholder,
} from '../assets/image/image';

// Start import components
import PhotoUploader from "../components/common/PhotoUploader";
import ImageUploadLoader from "components/common/ImageUploadLoader";
import { useSelector } from 'react-redux';
import { FILE_API } from 'constants/apiEndPoints/fileEndpoints';
import { CART_API } from 'constants/apiEndPoints/cartEndpoints';
import { useTranslation } from 'react-i18next';

const EditArticle = () => {
    let { id } = useParams();
    const { t } = useTranslation();
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
    const { requests } = useSelector(({ Request }: { Request: any }) => Request);
    const selectedArticle = requests.find((data:any)=> data.articleUid === id);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //Init state
    const [articles, setArticles] = useState<any>(requests);
    const [article, setArticle] = useState<any>(selectedArticle || {});
    const [isBrandsLoaded, setIsBrandsLoaded] = useState<boolean>(false);
    const [brands, setBrands] = useState<any>([]);
    const [brand, setBrand] = useState<any>({label: article?.brand, value: article?.brand, group: article?.brand_group});

    //Main category init state
    const [mainCategories, setMainCategories] = useState<any>([]);
    const [mainCategory, setMainCategory] = useState<any>(article.main_category);
    console.log({mainCategory})

    //Major category init state
    const [majorCategories, setMajorCategories] = useState<any>([]);
    const [majorCategory, setMajorCategory] = useState<any>({label: article.major_category, value: article.major_category});
    const [newMajorCategoryName, setNewMajorCategoryName] = useState<any>(article.major_category);
    console.log({majorCategory, newMajorCategoryName})

    //Minor category init state
    const [minorCategory, setMinorCategory] = useState<any>({label: article.minor_category, value: article.minor_category});
    const [newminorCategory, setNewMinorCategory] = useState<any>(article.minor_category);

    const [isShowHeightWidth, setIsShowHeightWidth] = useState<boolean>(false);
    const [width, setWidth] = useState<any>(article.width);
    const [height, setHeight] = useState<any>(article.height);
    const [images, setImages] = useState<any>(article?.images || []);
    const [isCheckFormValidation, setIsCheckFormValidation] = useState<boolean>(false);

    // Start lifecycle methods
    useEffect(() => {
        if(!selectedArticle && authUser) {
            navigate(`${getPrefixUrl()}/your-article`);
            return;
        } else if(!selectedArticle && !authUser) {
            navigate(`${getPrefixUrl()}/categories`);
            return;
        }
    }, [authUser]);

    useEffect(() => {
        // fetch all brands'
        fetchBrands();
        // fetch al Category
        fetchCategory();
    }, []);

    useEffect(() => {
        if(article && article.images) setImages(article.images);
        if(article && article.width) setWidth(article.width);
        if(article && article.height) setHeight(article.height);

        if(getLengthCategory(article.major_category)){
            setIsShowHeightWidth(true);
        } else setIsShowHeightWidth(false);
      
    }, [article]);

    useEffect(() => {
        initMajorCategories(mainCategories);
        mainCategories.forEach((mainCat:any) => {
            if(mainCat.key === mainCategory) {
                const majorCatList = mainCat.major_categories || [];
                const majorCat = majorCatList.find((item:any)=> item.key === majorCategory.value);
                setMajorCategory({...majorCategory, label: majorCat?.name});

                const minorCatList = majorCat?.minor_categories || [];
                const minorCat = minorCatList.find((item:any)=> item.key === minorCategory.value);
                setMinorCategory({...minorCategory, label: minorCat?.name});
            }
        });
    }, [mainCategories]);

    useEffect(() => {
        initMajorCategories(mainCategories);
    }, [mainCategory]);

    const initMajorCategories = (item:object) => {
        const majorCategories = mainCategories.find((mainCat:any) => mainCat.key === mainCategory );
        const majorCatList = majorCategories?.major_categories || [];
        if(majorCatList.length) {
            const majorCategoriesDropdown = mapArrayToDropdown(majorCatList, 'name', 'key');
            setMajorCategories(majorCategoriesDropdown);
        }
    };

    const fetchBrands = async () => {
        try {
            const response:any = await get(BRAND_API.fetch_all());
            if(response?.length) {
                response.forEach((brand:any)=>{
                    const obj:any = {
                        ...brand,
                        value: brand?.name || '',
                        label: brand?.name || '',
                    };
                    setBrands((curr:any) => [...curr, obj])
                })
                setIsBrandsLoaded(true);
            }
        } catch (error:any) {
            setIsBrandsLoaded(true);
            toast.error(error.message, toastValues);
        }
    };

    const fetchCategory= async () => {
        try {
            const { data } = await get(CATEGORY_API.fetch_all());
            setMainCategories(data.main_categories);
        } catch (error:any) {
            toast.error(error.message, toastValues);
        }
    };

    // Start events handler
    const onBrandChangeHandler = (item:any) => {
        setBrand(item);
        fetchImageFields(item.group, mainCategory, newMajorCategoryName);
    }

    // majorCategoryList
    const onChangeMainCategoryHandler = (itemList:any, key:any) => {
        setMajorCategories(itemList);
        setMainCategory(key);
        setNewMajorCategoryName(null)
    }

    const onChangeMajorCategoryHandler = (itemList:any, key:any) => {
        setNewMajorCategoryName(key)

        if(getLengthCategory(key)) setIsShowHeightWidth(true);
        else setIsShowHeightWidth(false);

        fetchImageFields(brand.group, mainCategory, key);
    }

    const getLengthCategory = (category:string) => {
        return (['men-bags', 'women-bags'].indexOf(category) !== -1)
    }

    // Start img uplod events handler
    const onUploadImgHandler = async (file:any, field:any) => {
        initImageFieldContext({
          ...field,
          loading: true
        })   

        try {
            // Start file upload
            const blob = file.slice(0, file.size, "image/jpeg");
            const newFile = new File([blob], file.name, { type: "image/jpeg" });

            // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
            const formData = new FormData();
            formData.append("file", newFile);
            formData.append("requestUid", article?.requestUid);
            formData.append("articleUid", article?.articleUid);
            if(article?.userId) formData.append("userId", article?.userId);
            const { data } = await post(FILE_API.upload(), formData, {isFile: true});

            if(data) {
                initImageFieldContext({
                    ...field,
                    url: data.publicUrl,
                    fileId: data.id,
                    loading: false
                });
            }
        } catch (error) {
            initImageFieldContext({
              ...field,
              loading: false
            })
            console.log('Error:', error);
        }

    };

    const fetchImageFields = async (brandGroup:String, selectedMainCatName:String, selectedMajorCatName:string) => {
        try {
          if(brandGroup && selectedMainCatName && selectedMajorCatName) {
            let params = `brand_group=${brandGroup || 'A'}`;
            if(selectedMainCatName) params += `&main_category=${selectedMainCatName}`;
            if(selectedMajorCatName) params += `&major_category=${selectedMajorCatName}`;
     
            const respnose = await get(FILE_API.getFileOptions(params));
            if(respnose && respnose?.fields?.length) {
    
              const imageFields = respnose.fields.map((image:any) => {
                const existImage = article?.images?.find((aImageField:any) => aImageField?.name === image.name );
                
                if(existImage?.fileId) {
                    image.url = existImage.url;    
                    image.fileId = existImage.fileId;    
                } else {
                    image.url = null;
                }

                image.loading = false;
                return image;
              });
    
              setImages(imageFields);
            }
          }
        } catch (error:any) {
          console.log('error:', error)
        }
      };
    
    const isInavalidForm = () => {
        if(isShowHeightWidth) {
            if(!(height && width)) return false;
        }
      
        const requiredNullField = images.find((img:any) => img.required && !img.url);
        if(requiredNullField && !requiredNullField.fileId) {
            return false
        } else return true;
    };


    // const getImagePlaceholder = (fieldInfo:any) => {
    //     let defaultPlaceholder = photoPlaceholder
    //     if(fieldInfo?.name === 'frontside') defaultPlaceholder = photoPlaceholderFront;
    //     else if(fieldInfo?.name === 'backside') defaultPlaceholder = photoPlaceholderBack;
        
    //     return defaultPlaceholder;
    // };

    const onRemoveImgHandler = async (fileInfo:any) => {
        initImageFieldContext({
            ...fileInfo,
            url: null,
            fileId: null
        });

    };

    const initImageFieldContext = (fieldInfo:any) => {
        if(fieldInfo.name) {
            const imageFields = images.map((image:any) => {
                if(image.name === fieldInfo.name) {
                    image = {
                        ...image,
                        ...fieldInfo
                    }
                }
                return image;
            });

            setImages(imageFields);
        }
    };
    
    // end img uplod events handler

    const onSubmitBrandAndType = async () => {
        if(!newMajorCategoryName) {
            toast.error(`Please Select Major Category!`, toastValues);
            return;
        } else if(!mainCategory){
            toast.error(`Please select MainCategory!`, toastValues);
            return;
        }
          
        if(isInavalidForm()){
            let editedArticle =  {
                id: article.id,
                uid:id,
                requestUid: article.requestUid,
                name: `${brand.value} ${newMajorCategoryName || newminorCategory}`,
                main_category: mainCategory,
                major_category: newMajorCategoryName,
                brand: brand.value,
                brand_group: brand.group,
                status: "Pending",
                height: isShowHeightWidth? height:'',
                width: isShowHeightWidth? width:'',
                images
            };
            
            const articlesList:any = [];
            articles.forEach((data:any) => {
                if(data?.uid === editedArticle?.uid) {
                    data = {...data, ...editedArticle};
                    editedArticle = data;
                }
                articlesList.push(data);
            });
    
            //Update redux store
            dispatch(updateRequest(articlesList));
    
            try{
                //Update article cart to DB
                await put(CART_API.update(editedArticle?.uid), editedArticle);
                await removeGarbageFiles(true);
            } catch(err){
                console.log(err);
            }
            navigate(`${getPrefixUrl()}/your-article`);
        } else {
            if(isCheckFormValidation) setIsCheckFormValidation(false);
            setTimeout(()=>{
                setIsCheckFormValidation(true);
            })
        }
    };

    const onReturn = async () => {
        await removeGarbageFiles(false);
        navigate(`${getPrefixUrl()}/your-article`);
    };

    const removeGarbageFiles = async (isConfirmed:boolean) => {
        const oldFileIds = article?.images?.map((oldFile:any) => oldFile?.fileId);
        const newFileIds = images?.map((newFile:any)=> newFile?.fileId);

        if(isConfirmed) {
            oldFileIds.forEach( async (fileId:any) => {
                if(!newFileIds.includes(fileId)) {
                    await del(FILE_API.delete(fileId));
                };
            });
        } else {
            newFileIds.forEach( async (fileId:any) => {
                if(!oldFileIds.includes(fileId)) {
                    await del(FILE_API.delete(fileId));
                };
            });
        }
    };

    const onChangeInputFieldHandler = (e:any, fieldName:string) =>{
        const fieldValue = e.currentTarget.value;

        if(fieldValue <= 0 || fieldValue > 100 ) {
            toast.error("Sorry! Please, set within 1 to 100", toastValues);
        } else {
            if(fieldName === 'height') setHeight(fieldValue);
            else if(fieldName === 'width') setWidth(fieldValue);
        }
    };

    const filterBrands = (inputValue: string) => {
        return brands.filter((i:any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const brandOptions = (inputValue:any, callback:any) => {
        setTimeout(() => {
            callback(filterBrands(inputValue));
        }, 1000);
    };
    // End events handler

    const getPlaceHolderImage = (image:any)=> {
        const imgName = concateToGetFileName(image?.name, newMajorCategoryName)
        return getImagePlaceholderForEditArticlePage(imgName, mainCategory)
    }
    return (
        <>
            <Container fluid className="background-white">
                <Card.Title className="page-title">{t('select_category_brand_edit_page')} </Card.Title>
                <Row>
                    <Col lg={3} md={3} xs={12}> </Col>
                    <Col lg={6} md={6} xs={12}>
                        <div className="form-field brand-search-field">
                            {
                                isBrandsLoaded? <AsyncSelect
                                    placeholder={`${t('select_brand')}...`}
                                    loadingMessage={() => `${t('searching')}...`}
                                    noOptionsMessage={() => `${t('no_options')}`}
                                    cacheOptions
                                    value={brand?.value? brand: ''}
                                    loadOptions={brandOptions}
                                    defaultOptions
                                    onChange={(item:any) => onBrandChangeHandler(item) }
                                />:<></>
                            }

                        </div>
                    </Col>
                    <Col lg={3} md={3} xs={12}> </Col>
                </Row>

                {/* Start product category */}
                <Row className="my-5 d-flex justify-content-center">
                    {
                        mainCategories.map((genderCategory:any, index:number)=> {
                            return (
                                <Col key={`${genderCategory.key}_${index}`} className="text-center" lg={3} md={3} xs={6}>

                                    <Form.Check
                                        className="category-card"
                                        type="radio"
                                        id={`${genderCategory.key}-category`} >

                                        <Form.Check.Input
                                            type="radio"
                                            className="bns-checkbox bns-card round"
                                            name="mainCategory"
                                            checked={genderCategory.key === mainCategory}
                                            onChange={(e) => onChangeMainCategoryHandler(genderCategory.major_categories, genderCategory.key)} />

                                        <Form.Check.Label className="cursor-pointer">
                                            <Card className="category-card-item">
                                                <div className="category-card-item-content">
                                                    <img src={genderCategory.key === 'men' ? parentCategoryMale : parentCategoryFemale} title={genderCategory.name}  />
                                                    <p className="text-uppercase">{genderCategory.name}</p>
                                                </div>
                                            </Card>
                                        </Form.Check.Label>

                                    </Form.Check>
                                </Col>
                            )
                        })
                    }
                </Row>

                <h3 className="text-center">{t('product_type')}</h3>
                <Row className="my-5 d-flex justify-content-center">
                    {
                        majorCategories.map((mCategory:any, index:number)=> {
                            return (
                                <>
                                    {
                                        !(mCategory.key === 'men-underwear' || mCategory.key === 'women-underwear')? 
                                        
                                        <Col key={`${mCategory.key}_${index}`} className="mb-4" lg={2} md={3} xs={6}>
                                            <Form.Check className="category-card" type="radio" id={`${mCategory.key}-major-category`}>
                                                <Form.Check.Input type="radio" 
                                                                name="majorCategory"
                                                                className="bns-checkbox bns-card round"
                                                                checked={mCategory.key === newMajorCategoryName}
                                                                onChange={(e) => onChangeMajorCategoryHandler(mCategory.minor_categories, mCategory.key)}
                                                />
                                                <Form.Check.Label>
                                                    <Card className="category-card-item">
                                                        <div className="category-card-item-content">
                                                            <img src={majorCategoriesImg(mCategory.key)} title={mCategory.label}  />
                                                            <p className="text-uppercase">{mCategory.name}</p>
                                                        </div>
                                                    </Card>
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Col>:''
                                    }
                                </>
                            )
                        })
                    }
                </Row>

                {/* Start photos section  */}

                {
                    newMajorCategoryName? <>
                    <h3 className="text-center">{t('image_upload.photo')}</h3>
                        <Row className="my-4 d-flex justify-content-center">
                            {
                            images.map((image:any, index:number) => {
                                return (
                                    <Col key={`${image.name}_${index}`} lg={3} md={4} xs={6} className="mb-4">
                                        <div className={`add-photo-box ${isCheckFormValidation && (image?.required && !image.url)?'invalid':''}`}>
                                        {
                                            image?.loading?  <ImageUploadLoader height={130} width={130} />:<img
                                                className={`selected-img ${
                                                    image?.url ?? "opacity-75"
                                                  }`}
                                                src={image?.url || getPlaceHolderImage(image)}
                                            />
                                        }
                                        <div className="photo-box-title"> 
                                            {t(image.name)}
                                            { image.required? <span className="form-required-icon">*</span>:'' }
                                        </div>
                                        <PhotoUploader
                                            elId={image?.name}
                                            isImageLoaded={image?.url}
                                            selectedImage={(file: any) => onUploadImgHandler(file, image)}
                                            onRotted={() => console.log("rotted trigger")}
                                            onReset={() => onRemoveImgHandler(image)}
                                        />
                                        <small className="invalid-text">{t('form.invalid_message')}</small>
                                        </div>
                                    </Col>
                                )
                            })
                            }
                        </Row>
                    </>:<></>
                }

                {/* End photos section  */}

                {/* Start height/width section  */}
                {
                    isShowHeightWidth? <>
                    <div className="text-center my-5">
                        <h5>{t("additional_information_required")}</h5>
                    </div>
                    <Row className="my-4">
                        <Col lg={6} md={6} xs={6}>
                            <div className={`${isCheckFormValidation && !width?'invalid':''}`}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                    type="number"
                                    min="1"
                                    max="100"
                                    value={width}
                                    placeholder={t('bag_width') || ''}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    onChange={(e:any)=>onChangeInputFieldHandler(e, 'width')}
                                    />
                                    <InputGroup.Text id="photoWidth"
                                    >cm</InputGroup.Text>
                                </InputGroup>
                                <small className="invalid-text">{t('form.invalid_message')}</small>
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                            <div className={`${isCheckFormValidation && !height?'invalid':''}`}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        max="100"
                                        value={height}
                                        placeholder={t('bag_height') || ''}
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon3"
                                        onChange={(e:any)=>onChangeInputFieldHandler(e, 'height')}
                                    />
                                    <InputGroup.Text id="photoHeight">cm</InputGroup.Text></InputGroup>
                                <small className="invalid-text">{t('form.invalid_message')}</small>
                            </div>
                        </Col>
                    </Row>
                    </>:<></>
                }
                {/* End height/width section  */}

                <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-between">
                    <Button onClick={() => onReturn() } className="round-btn" variant="dark" size="sm"> {t('return')} </Button>
                    {/* <Button disabled={isInavalidForm()} onClick={() => navigate(`${getPrefixUrl()}/your-article`) } className="round-btn" variant="dark" size="sm"> {t('return')} </Button> */}
                    <Button onClick={() => onSubmitBrandAndType() } className="round-btn" variant="dark" size="sm"> {t('continue')}  </Button>
                </div>

            </Container>
        </>
    );
};

export default EditArticle;