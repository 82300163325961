export const AUTH_API = {
    sendOtp: ()=> `/api/send-otp`,
    verifyOtp: ()=> `/api/code-verify`,
    bnsHubToken: ()=> `/api/bns-token`,
    login: ()=> `/api/login`,
    me: ()=> `/api/me`,
    logout: ()=> `/api/auth/logout`,
    forgetPassword: ()=> `/api/auth/forgot-password`,
    updatePassword: ()=> `/api/auth/update-password`,
    updateLanguage: ()=> `/api/update-language`
}