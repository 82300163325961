import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import jwtDecode from "jwt-decode";

// Import redux actions
import { setDevice } from "./store/auth/device/actions";
import { loginSuccess, logoutUserSuccess } from "./store/auth/login/actions";
import { privateRoutes, publicRoutes } from "./routes/";
import {isEmbadedInWP } from 'helpers/common_helper';
import { get } from 'helpers/api_helpers';
import { AUTH_API } from 'constants/apiEndPoints/securityEndPoints';

// Import Components
import Header from "./components/nav/landing/Header";
import ToastBody from 'common/ToastBody';

const App = (props: any) => {
  const dispatch = useDispatch();

  const localTheme = localStorage.getItem("theme");
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const { isMobile, viewWidth } = useSelector(
    ({ Device }: { Device: any }) => Device
  );
  const [isAuth, setIsAuth] = useState(false);
  const [isInIframe, setIsInIframe] = useState(false);
  const [theme, setTheme] = useState<string>(localTheme ? localTheme : "light");
  const [color, setColor] = useState<string>("#000");
  const [bgColor, setBGColor] = useState<string>("#fff");

  //Start react effect life cycle
  //Theme settings
  useEffect(() => {
    document.body.classList.remove("light");
    document.body.classList.remove("dark");
    // document.body.style.backgroundColor = bgColor;
    document.body.style.color = color;
    document.body.classList.add(theme);
    onWebsiteLoaded();
  }, [bgColor, color, theme]);

  useEffect(() => {
   if(isInIframe) document.body.classList.add('buddyandselly');
   else document.body.classList.remove('buddyandselly');
  }, [isInIframe]);

  useEffect(() => {
    if (authUser) setIsAuth(true);
    else setIsAuth(false);
  }, [authUser]);

  useEffect(() => {
    theme === "dark" ? setColor("#c1c1c1") : setColor("#000");
    theme === "dark" ? setBGColor("#202025") : setBGColor("#eff4f8");
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    const localeAuth: any = localStorage.getItem("authUser");
    const authUser = localStorage.getItem("authUser")
      ? JSON.parse(localeAuth)
      : null;

    let accessToken = (authUser && authUser.accessToken) || "";
    let userInfo = accessToken ? jwtDecode(accessToken) : null;
    dispatch(loginSuccess(userInfo));

    if (accessToken) {
      const userInfo: any = jwtDecode(accessToken);
      if (userInfo.exp * 1000 < Date.now()) {
        dispatch(logoutUserSuccess());
      } else {
        delete userInfo.accessToken;
        dispatch(loginSuccess(userInfo));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    getAccessToken();
    initEventHandler();
    
    //Add class in body for track stay embeded page in wp
    if(isEmbadedInWP()) {
      document.body.classList.add("send-site-loaded");
    }

    // 👇️ remove the event listener when the component unmounts
    return () => {
      if(isEmbadedInWP()) {
        document.body.classList.add("send-site-loaded");
      }
    };
  }, []);
  //End react effect life cycle

  //Start Event handler
  window.addEventListener("resize", () => {
    setTimeout(() => {
      initEventHandler();
    }, 100);
  });

  const initEventHandler = () => {
    const width = window.outerWidth;
    if (width !== viewWidth) {
      dispatch(setDevice({ width }));
    }
  };

  const getThemeClassName = () => {
    return theme === "dark" ? "dark" : "";
  };

  const onWebsiteLoaded = () => {
    const is =  (window === window.parent) ? false : true;
    return setIsInIframe(is);
  };

  const getViewClassname = () => {
    return isMobile ? `mobile-view` : `desktop-view`;
  };

  const onMainHandlerEvent = () => {
    // Find an element by class and check if it has a specific class
    const navElement:any = document.getElementById('responsive-navbar-nav');
    if (navElement.classList.contains('show')) {
      const closeNavMenu:any = document.querySelector('.navbar-toggler');
      closeNavMenu?.click();
    }
  };

  const getAccessToken = async () => {
    const bnsAuthRes = await get(AUTH_API.bnsHubToken());
    localStorage.setItem('aToken', bnsAuthRes?.access_token);
  };
  //End Event handler

  return (
    <React.Fragment>
      <BrowserRouter>
        <header>
          <Header theme={theme} />
        </header>
        <main
          className={`page-content ${getViewClassname()} ${getThemeClassName()}`}
          onClick={()=> onMainHandlerEvent()}
        >
          <Routes>
            {isAuth
              ? privateRoutes.map((route:any, i:any) => {
                return (
                  <Route key={i} path={route.path} element={route.element} />
                );
              })
              : publicRoutes.map((route:any, i:any) => {
                return (
                  <Route key={i} path={route.path} element={route.element} />
                );
              })}
          </Routes>
        </main>
        {/* <footer> <Footer theme={theme} /> </footer> */}
      </BrowserRouter>

      {/* Toast Body for All Toaster.  */}
      <ToastBody />
    </React.Fragment>
  );
};

export default App;
