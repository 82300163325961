import { useState } from 'react';
import ReactSelect from 'react-select';
import { Modal, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { put, putBnsApi } from 'helpers/api_helpers';
import { REQUEST_BNS_API } from 'constants/apiEndPoints/bnsApi/requestEndpoints';
import { toastValues } from 'common/toastValues';
import { REQUEST_API } from 'constants/apiEndPoints/requestEndpoints';
import { toast } from 'react-toastify';

const ConfirmRequestModal = (props:any) => {
    const {onHide, id} = props;
    const { t } = useTranslation();
    const [value, setValue] = useState<any>({label: `${t('confirm_request_modal.first_option')}`, value: `${t('confirm_request_modal.first_option')}`});


    const handleSend = async () => {
        onHide(true)
        try {
            const response = await putBnsApi(REQUEST_BNS_API.rejected_by_customer(id), {});
            if (response.status === "UPDATED") {
                toast.success("Successfully Updated!", toastValues);
            }
            // update in local api
            await put(REQUEST_API.update(id), {status: "cancelled"});
        } catch (error) {
            toast.error("Some things went wrong!", toastValues);
        }
    }

    const reason = [
        {
            label: `${t('confirm_request_modal.first_option')}`,
            value: `${t('confirm_request_modal.first_option')}`,
        },
        {
            label: `${t('confirm_request_modal.second_option')}`,
            value: `${t('confirm_request_modal.second_option')}`,
        },
        {
            label: `${t('confirm_request_modal.third_option')}`,
            value: `${t('confirm_request_modal.third_option')}`,
        },
        {
            label: `${t('confirm_request_modal.fourth_option')}`,
            value: `${t('confirm_request_modal.fourth_option')}`,
        },
    ]

    const onSearchSelectChangeHandler = (item:any) => {
        setValue(item)
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="hide-title"></Modal.Header>

            <h4 className='text-center'> {t('confirm_request_modal.title')}</h4>

            <Modal.Body>

                <div className='text-center'>
                    {t('confirm_request_modal.description_one')}
                    <br />
                    <br />
                    {t('confirm_request_modal.description_two')}
                </div>

                <div className="form-field brand-search-field mt-4">
                    <ReactSelect placeholder={"Marke eingeben..."} options={reason} value={value}  onChange={(onSearchSelectChangeHandler)} />
                </div>

                <div className="text-center py-3">
                    <Button onClick={handleSend} className="round-btn w-100" variant="dark" size="sm">{t('confirm_request_modal.button')}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmRequestModal;