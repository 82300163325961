/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { toastValues } from "common/toastValues";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Start import components
import ImageUploadLoader from "components/common/ImageUploadLoader";
import PhotoRestrictionsModal from "components/common/PhotoRestrictionsModal";
import PhotoUploader from "components/common/PhotoUploader";
import Prompt from "components/common/Prompt";
import { getImagePlaceholder, getPrefixUrl } from "helpers/common_helper";


// Start import helpers
import { CART_API } from "constants/apiEndPoints/cartEndpoints";
import { FILE_API } from "constants/apiEndPoints/fileEndpoints";
import { del, get, post } from "helpers/api_helpers";
import { addToRequest, clearSelectRequest } from "store/request/actions";

const PhotoPage = () => {
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    setUser(authUser);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state }: any = useLocation();
  const { selectRequest } = useSelector(
    ({ Request }: { Request: any }) => Request
  );
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [isShowTermModal, setIsShowTermModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(state?.isEdit || false);
  const [article, setArticle] = useState<any>(state?.article || {});
  const [images, setImages] = useState<any>([]);

  const [fromPathname, setFromPathname] = useState<string>(
    state?.fromPathname || ""
  );
  const [isShowHeightWidth, setIsShowHeightWidth] = useState<boolean>(false);

  const [width, setWidth] = useState<any>("");
  const [height, setHeight] = useState<any>("");
  const [isCheckFormValidation, setIsCheckFormValidation] =
    useState<boolean>(false);

  useEffect(() => {
    if (!(selectRequest && selectRequest.uid)) {
      navigate(`${getPrefixUrl()}/guideline`);
    }

    //Get selected request images fields options
    getImageFields();

    if (!isEdit) {
      setIsShowTermModal(!isShowTermModal);
    }

    setIsShowTermModal(!isShowTermModal);

    if (getLengthCategory(selectRequest.major_category)) {
      setIsShowHeightWidth(true);
    } else setIsShowHeightWidth(false);
  }, []);

  // Start events handler
  const onUploadImgHandler = async (file: any, field: any) => {
    initImageFieldContext({
      ...field,
      loading: true,
    });

    try {
      // Start file upload
      const blob = file.slice(0, file.size, "image/jpeg");
      const newFile = new File([blob], file.name, { type: "image/jpeg" });
      // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
      const formData = new FormData();
      formData.append("file", newFile);
      formData.append("requestUid", selectRequest?.requestUid);
      if (user?.id) formData.append("userId", user.id);
      formData.append("articleUid", selectRequest?.uid);
      const { data } = await post(FILE_API.upload(), formData, {
        isFile: true,
      });

      if (data) {
        initImageFieldContext({
          ...field,
          url: data.publicUrl,
          fileId: data.id,
          loading: false,
        });
      }
    } catch (error) {
      initImageFieldContext({
        ...field,
        loading: false,
      });
    }
  };

  const getImageFields = async () => {
    try {
      if (
        selectRequest?.brand_group &&
        selectRequest?.main_category &&
        selectRequest?.major_category
      ) {
        let params = `brand_group=${selectRequest.brand_group || "A"}`;
        if (selectRequest.main_category)
          params += `&main_category=${selectRequest.main_category}`;
        if (selectRequest.major_category)
          params += `&major_category=${selectRequest.major_category}`;

        const respnose = await get(FILE_API.getFileOptions(params));
        if (respnose && respnose?.fields?.length) {
          const imageFields = respnose.fields.map((image: any) => {
            image.url = null;
            image.loading = false;
            return image;
          });

          setImages(imageFields);
        }
      }
    } catch (error: any) {
      console.log("error:", error);
    }
  };

  const isValidPhotoForm = () => {
    if (isShowHeightWidth) {
      if (!(height && width)) return false;
    }

    const requiredNullField = images.find(
      (img: any) => img.required && !img.url
    );

    if (requiredNullField && !requiredNullField.fileId) {
      return false;
    } else return true;
  };

  const getLengthCategory = (category: string) => {
    return ["men-bags", "women-bags"].indexOf(category) !== -1;
  };

  const onRemoveImgHandler = async (fileInfo: any) => {
    try {
      if (fileInfo?.fileId) {
        const { status } = await del(FILE_API.delete(fileInfo?.fileId));

        if (status) {
          initImageFieldContext({
            ...fileInfo,
            url: null,
            fileId: null,
          });
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const onSubmitPhotoHandler = async () => {
    if (isValidPhotoForm()) {
      if (isEdit && article.uid) {
        const updatedArticle = {
          ...article,
          width,
          height,
          images,
        };

        navigate(fromPathname, { state: updatedArticle });
      } else {
        const request = {
          ...selectRequest,
          userId: user?.id || null,
          width,
          height,
          articleUid: selectRequest?.uid,
          images,
        };

        dispatch(addToRequest(request));
        dispatch(clearSelectRequest());

        const responseLocal = await post(CART_API.create(), request, {
          isArray: true,
        });
        if (responseLocal) {
          toast.success("Successfully Added to Cart!", toastValues);
        } else {
          toast.error("Some things went wrong!", toastValues);
        }
        navigate(`${getPrefixUrl()}/your-article`);
      }
    } else {
      if (isCheckFormValidation) setIsCheckFormValidation(false);
      setTimeout(() => {
        setIsCheckFormValidation(true);
      });
    }
  };

  const onChangeInputFieldHandler = (e: any, fieldName: string) => {
    const fieldValue = e.currentTarget.value;

    if (fieldValue <= 0 || fieldValue > 100) {
      toast.error("Sorry! Please, set within 1 to 100", toastValues);
    } else {
      if (fieldName === "height") setHeight(fieldValue);
      else if (fieldName === "width") setWidth(fieldValue);
    }
  };

  const initImageFieldContext = (fieldInfo: any) => {
    if (fieldInfo.name) {
      const imageFields = images.map((image: any) => {
        if (image.name === fieldInfo.name) {
          image = {
            ...image,
            ...fieldInfo,
          };
        }
        return image;
      });

      setImages(imageFields);
    }
  };

  // End events handler

  return (
    <>
      <Container fluid className="background-white container-min-height">
        <Row>
          <Col lg={3} md={3} xs={12}>
            {" "}
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Card.Title className="page-title">
              {t("image_upload.title_add_photos")}
            </Card.Title>
            <Card.Subtitle className="text-center">
              {t("image_upload.image_upload_page_subtitle")}
            </Card.Subtitle>

            {/* Start photo */}
            <Row className="my-4">
              {images.map((image: any, index: number) => {
                return (
                  <Col
                    key={`${image.name}_${index}`}
                    lg={3}
                    md={4}
                    xs={6}
                    className="mb-4"
                  >
                    <div
                      className={`add-photo-box ${
                        isCheckFormValidation && image?.required && !image.url
                          ? "invalid"
                          : ""
                      }`}
                    >
                      {image?.loading ? (
                        <ImageUploadLoader height={130} width={130} />
                      ) : (
                        <img
                          className={`selected-img ${
                            image?.url ?? "opacity-75"
                          }`}
                          src={
                            image?.url ||
                            getImagePlaceholder(image, selectRequest)
                          }
                        />
                      )}
                      <div className="photo-box-title">
                        {t(image.name)}
                        {image.required ? (
                          <span className="form-required-icon">*</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <PhotoUploader
                        elId={image?.name}
                        isImageLoaded={image?.url}
                        selectedImage={(file: any) =>
                          onUploadImgHandler(file, image)
                        }
                        onRotted={() => console.log("rotted trigger")}
                        onReset={() => onRemoveImgHandler(image)}
                      />
                      <small className="invalid-text">
                        {t("form.invalid_message")}
                      </small>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {/* End Images      */}

            {/* Start height/width section  */}
            {isShowHeightWidth ? (
              <>
                <div className="text-center my-4">
                  <h5>{t("additional_information_required")}</h5>
                </div>
                <Row className="my-4">
                  <Col lg={6} md={6} xs={6} className="mb-2">
                    <div
                      className={`${
                        isCheckFormValidation && !width ? "invalid" : ""
                      }`}
                    >
                      <InputGroup className="mb-1">
                        <Form.Control
                          type="number"
                          min="1"
                          max="100"
                          value={width}
                          placeholder={t("bag_width") || ""}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e: any) =>
                            onChangeInputFieldHandler(e, "width")
                          }
                        />
                        <InputGroup.Text id="photoWidth">cm</InputGroup.Text>
                      </InputGroup>
                      <small className="invalid-text">
                        {t("form.invalid_message")}
                      </small>
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} className="mb-3">
                    <div
                      className={`${
                        isCheckFormValidation && !height ? "invalid" : ""
                      }`}
                    >
                      <InputGroup className="mb-1">
                        <Form.Control
                          type="number"
                          min="1"
                          max="100"
                          value={height}
                          placeholder={t("bag_height") || ""}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon3"
                          onChange={(e: any) =>
                            onChangeInputFieldHandler(e, "height")
                          }
                        />
                        <InputGroup.Text id="photoHeight">cm</InputGroup.Text>
                      </InputGroup>
                      <small className="invalid-text">
                        {t("form.invalid_message")}
                      </small>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            {/* End height/width section  */}

            <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-center">
              <Button
                // disabled={!isValidPhotoForm()}
                onClick={() => onSubmitPhotoHandler()}
                className="round-btn"
                variant="dark"
                size="sm"
              >
                {t("continue_to_the_summary")}
              </Button>
            </div>
          </Col>
          <Col lg={3} md={3} xs={12}>
            {" "}
          </Col>
        </Row>
      </Container>

      {/* Start load components  */}
      <PhotoRestrictionsModal
        show={isShowTermModal}
        onHide={() => setIsShowTermModal(!isShowTermModal)}
      />
      <Prompt />
    </>
  );
};

export default PhotoPage;
