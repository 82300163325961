import { Container, Card, Button, Form, Col, Row} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Start import images
import {
  sendIcon,
} from 'assets/image/image';
import { useTranslation } from 'react-i18next';
import { getPrefixUrl } from 'helpers/common_helper';

const LoginPage = () => {
  const navigate = useNavigate();
  const [articleData, setarticleData] = useState<object>({});
  const { t } = useTranslation();

  useEffect(()=>{

  }, []);

  const redirectMyRequest = () =>{
    navigate(`${getPrefixUrl()}/my-requests`)
  };

// Start events handler
// End events handler

  return (
      <>
        <Container fluid className="background-white container-min-height">
          <Card.Title className="page-title">{t('done')}</Card.Title>

          {/* Start product category */}
          <Row className="mb-4">
            <Col lg={4} md={3} xs={12}></Col>
            <Col lg={4} md={6} xs={12} className="text-center">
              <img src={sendIcon} className="mb-4" />
              <p>{t('request_req_thankyou_msg.request_req_thankyou_msg_1')}</p>
              <p>{t('request_req_thankyou_msg.request_req_thankyou_msg_2')}</p>
              <p>{t('request_req_thankyou_msg.request_req_thankyou_msg_3')}</p>
            </Col>
            <Col lg={4} md={3} xs={12}> </Col>
          </Row>

          <div className="how-to-form-footer pt-2 pb-5 px-3 text-center">
            <Button onClick={()=>redirectMyRequest()} className="round-btn" variant="dark" size="sm"> {t('request_req_thankyou_msg.to_my_inquiries')} </Button>
          </div>

        </Container>
      </>
  );
};

export default LoginPage;
