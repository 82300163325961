/* eslint-disable jsx-a11y/alt-text */
import { Container, Nav, Navbar, Dropdown, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Start import helppers
import { logoutUserSuccess } from 'store/auth/login/actions';
import { resetRequests } from 'store/request/actions';
import { toastValues } from 'common/toastValues';
import {isEmbadedInWP, getPrefixUrl } from 'helpers/common_helper';
import { put } from 'helpers/api_helpers';

// Start import images
import {
  desktopLogo,
  mobileLogo,
  enLanguageFlag,
  deLanguageFlag,
} from 'assets/image/image';
import { AUTH_API } from 'constants/apiEndPoints';

const Header = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(localStorage.getItem('i18nextLng') || 'de');
  const { isMobile } = useSelector(({ Device }: { Device: any }) => Device);

  useEffect(()=>{
    setIsAuth(!!authUser);
  }, [authUser]);

  const onLanguageChangeHandler = async (lng:string) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
    
    if(isAuth) {
      try {
        await put(AUTH_API.updateLanguage(), {language: lng});
      } catch (error) {
        console.log('error:', error);
      }
    }

  }

  const onNavigatehandler =(e: FormEvent<EventTarget>, path: string): void => {
    if(path && path === `/my-requests`) {
      if(!isAuth) {
        navigate(`${getPrefixUrl()}/email-input`,{ state: {fromPathname: `${getPrefixUrl()}/my-requests` } });
      } else navigate(`${getPrefixUrl()}${path}`)

    } else if(path) navigate(`${getPrefixUrl()}${path}`);
  };

  const onLogoutHandler =(e: FormEvent<EventTarget>): void => {
    dispatch(logoutUserSuccess());
    dispatch(resetRequests());
    toast.success("Successfully logout", toastValues);
    navigate(`${getPrefixUrl()}/`);
  };

  return (
      <Navbar fixed="top" className={isMobile?`mobile-view`:`desktop-view`} collapseOnSelect expand="lg" bg="light" variant="light">
        <Container fluid>
          <div className="toggle-logo-container cursor-pointer">
            <div> <Navbar.Toggle aria-controls="responsive-navbar-nav" /> </div>
            <Navbar.Brand onClick={()=> navigate('/')}><img src={isMobile? mobileLogo:desktopLogo} /> </Navbar.Brand>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* Brand Nav  */}
            </Nav>
            <Nav>
              {/*Start It will be work for wordpress site */}
              {
                isEmbadedInWP()? <>
                    <Button id="js-send-home" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/send')}
                    > {t('home')} </Button>
                    <Button id="js-send-guidelin-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/guideline')}
                    > {t('create_request')} </Button>
                    <Button id="js-send-categories-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/categories')}
                    > categories </Button>
                    <Button id="js-send-photo-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/photo')}
                    > photo </Button>
                    <Button id="js-send-articles-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/your-article')}
                    > articles </Button>
                    <Button id="js-send-email-input-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/email-input')}
                    > email </Button>
                    <Button id="js-send-welcome-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/welcome')}
                    > welcome </Button>
                    <Button id="js-send-additionalInfo-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/additional-infos')}
                    > additional info </Button>
                    <Button id="js-send-myrequest-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/my-requests')}
                    > {t('my_requests')} </Button>
                    <Button id="js-send-payout-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/payout')}
                    > payout </Button>
                    <Button id="js-send-confirm-request-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/confirm-request')}
                    > Confirm request </Button>
                    <Button id="js-send-accept-request-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/accept-request')}
                    > accept request </Button>
                    <Button id="js-send-german-lang-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={()=> onLanguageChangeHandler('de')}
                    > German </Button>
                    <Button id="js-send-english-lang-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={()=> onLanguageChangeHandler('en')}
                    > English </Button>
                    <Button id="js-send-italian-lang-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={()=> onLanguageChangeHandler('it')}
                    > Italian </Button>
                    <Button id="js-send-polish-lang-btn" variant="info" className="nav-round-btn my-2" size="sm"
                            onClick={()=> onLanguageChangeHandler('pl')}
                    > Polish </Button>
                </>:<></>
              }
              {/*End It will be work for wordpress site */}
           
              <Dropdown>
                <Dropdown.Toggle className="language-toggle-btn mr-5 mt-3" variant="primary" id="language" size="sm">
                  <img src={language ==='de'?deLanguageFlag:enLanguageFlag} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={()=>onLanguageChangeHandler('en')}> <img src={enLanguageFlag} className="mr-1" /> English </Dropdown.Item>
                  <Dropdown.Item onClick={()=>onLanguageChangeHandler('de')}> <img src={deLanguageFlag} className="mr-1" /> Germany</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown id="js-actions-dropdown">
                <Dropdown.Toggle className="language-toggle-btn mr-5 my-3" variant="default" id="profile" size="sm">
                  {
                    isAuth? <i className="fa fa-user-check logged-user-color"></i> :<i className="fa fa-user "></i>
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e)=> onNavigatehandler(e, '/guideline')}> {t('create_request')} </Dropdown.Item>
                  <Dropdown.Item onClick={(e)=> onNavigatehandler(e, '/my-requests')}> {t('my_requests')} </Dropdown.Item>
                  {
                    isAuth? <Dropdown.Item onClick={(e)=>onLogoutHandler(e)}> {t('logout')} </Dropdown.Item>:''
                  }
                </Dropdown.Menu>
              </Dropdown> 
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
};

export default Header;