// Start Import package
import { Fragment } from "react";
import Card from "react-bootstrap/Card";
import { reviewRequestProduct } from "assets/image/image";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//apply base url for axios
const ASSET_URL = process.env.REACT_APP_ASSET_BASE_URL;

// Start import helpers

const RequestCard = (props: any) => {
    const { onClick } = props;
    const { t } = useTranslation();

    return (
        <Fragment>
            <Card
                onClick={onClick}
                className="product-card"
                style={{ minWidth: "18rem" }}
            >
                <Card.Body>
                    <div
                        className="card-img"
                        style={{ backgroundImage: `url(${ASSET_URL}${reviewRequestProduct})` }}
                    ></div>
                    <div className='d-flex justify-content-center mt-3'><Button className="round-btn" variant="dark" size="sm"> {t('my_requests')} </Button></div>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default RequestCard;
