import { all, fork } from 'redux-saga/effects';
import AuthSaga from './auth/login/saga';

//public
import ForgetSaga from './auth/forgetpassword/saga';
import authResetSaga from './auth/resetpassword/saga';
// import AuthSaga from './auth/login/saga';
// import ProfileSaga from './auth/profile/saga';
// import AccountSaga from './auth/register/saga';
// import dashboardSaga from './dashboard/saga';
// import calendarSaga from 'pages/Calendar/store/saga';
// import invoiceSaga from './invoices/saga';

export default function* rootSaga() {
  yield all([
    // public,
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(authResetSaga),
    // fork(AuthSaga),
    // fork(ProfileSaga),
    // // fork(calendarSaga),
    // // fork(invoiceSaga),
    // fork(dashboardSaga),
  ]);
}
