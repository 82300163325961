import { ADD_TO_ADDRESS, CLEAR_ADDRESS } from "./actionsTypes";

const initialState = {
    address: {}
};
const Address = (state = initialState , action: any) => {
    const {payload, type} = action;
    switch(type) {
        case ADD_TO_ADDRESS: {
            const address:any = {...state.address, ...payload};
            state = {
                ...state,
                address
            };
        }
            break;
        case CLEAR_ADDRESS: {
            const address:any = {};
            state = {
                ...state,
                address
            };
        }
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};
export default Address;
