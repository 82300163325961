import { Container, Card, Button, Form, Col, Row, Spinner} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect , FormEvent, Fragment} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

// Start import helpers
import { isOTPCodeValid } from 'common/validations';
import { toastValues } from 'common/toastValues';
import { post } from 'helpers/api_helpers';
import { AUTH_API } from 'constants/apiEndPoints/securityEndPoints';
import { loginSuccess } from "../store/auth/login/actions";
import OTPTimer from 'components/common/OTPTimer';
import { useTranslation } from 'react-i18next';
import { isEmbadedInWP, getPrefixUrl } from 'helpers/common_helper';

const OTPVerifyPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state }:any = useLocation();
  const pathname = state?.pathname;
  const email = state?.email;
  const fromPathname = state?.fromPathname;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ isOTPCodeInvalid, setIsOTPCodeInvalid ] = useState<boolean>(true);
  const [ OTPCode, setOTPCode ] = useState<any>('');

  useEffect(()=>{
    if(!email) {
      navigate(`${getPrefixUrl()}/login`, { state });
    }
  }, [email]);

// Start events handler
  const otpEmailHandler = (e: FormEvent<EventTarget>): void => {
    let target = e.target as HTMLInputElement;
    const value = target.value;
    setOTPCode(value);
    setIsOTPCodeInvalid(!isOTPCodeValid(value));
  };

  const resendOTPHandlder = async (e:FormEvent<EventTarget>): Promise<void> => {
    e.preventDefault();
    const data = {
      email: email,
      type: 'registration'
    };

    if(!email) {
      navigate(`${getPrefixUrl()}/login`, { state });
      toast.error('Please, Again enter your email!', toastValues);
      return;
    }
    
    try {
      const res = await post(AUTH_API.sendOtp(), data);
      if(res) {
        toast.success('Successfully re-sent. Please check your email!', toastValues);
      }
    } catch (error:any) {
      toast.error(error?.response?.data?.title, toastValues);
    }
  };

  const sendOTPFormHandler = async () => {
    if(!isOTPCodeInvalid){

      setIsLoading(true);
      try {
        const response = await post(AUTH_API.verifyOtp(), {code: OTPCode});

        let userInfo = response && response.accessToken ? jwtDecode(response.accessToken) : null;
        localStorage.setItem('authUser', JSON.stringify(response));
        dispatch(loginSuccess(userInfo));

        setIsLoading(false);
        toast.success('Verified your code!', toastValues);
        navigate( fromPathname || pathname || '/' );

        if(isEmbadedInWP()) {
          const windowVar:any = window;
          windowVar?.setSendAuth();
        }

      } catch (error:any) {
        setIsLoading(false);
        const { data } = error?.response || {};
        toast.error(data.message, toastValues);
      }
    }
  }
// End events handler

  return (
      <Fragment>
        <Container fluid className="background-white container-min-height">
          <Card.Title className="page-title">OTP CODE</Card.Title>

          <Row>
            <Col lg={4} md={3} xs={12}></Col>
            <Col lg={4} md={6} xs={12}>
              <p className="text-center">Rufe nun deine Authenticator app auf und
                gebe hier den generierten Code ein.</p>
              <Form.Group className="mb-3" controlId="formOrEmail">
                <Form.Control type="text" placeholder="Enter code" defaultValue={OTPCode} onChange={(e)=>otpEmailHandler(e)} />
              </Form.Group>
              <OTPTimer initMinutes={3} initSeconds={1} text="Time:" buttonText={t('send_again')} resend={resendOTPHandlder} />
              <div className="d-flex justify-content-center mt-4">
                <Button variant="dark" className={isLoading?'disabled':''} onClick={()=> isLoading?'':sendOTPFormHandler()} disabled={isOTPCodeInvalid}>
                  {isLoading ? (
                    <><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> {t('loading')}...</>
                    ) : ( t('proceed_to_verification') )}
                </Button>
              </div>
            </Col>
            <Col lg={4} md={3} xs={12}></Col>
          </Row>
        </Container>
      </Fragment>
  );
};

export default OTPVerifyPage;
