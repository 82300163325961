// Start Import package
import Spinner from 'react-bootstrap/Spinner';

const ImageUploadLoader = (props:any) => {
  const { height, width } = props;
  
  return (
    <div className="img-upload-loader" style={{height, width}}> <Spinner animation="border" variant="secondary" /> </div>
  );
};

export default ImageUploadLoader;
