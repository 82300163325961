import { ADD_TO_SHIPMENT, CLEAR_SHIPMENT} from './actionsTypes';

export const addToShipment = (shipment: any) => {
  return {
    type: ADD_TO_SHIPMENT,
    payload: shipment,
  };
};

export const clearShipment = () => {
  return {
    type: CLEAR_SHIPMENT,
    payload: null,
  };
};
