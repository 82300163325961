import { 
    LOGIN_SUCCESS, LOGIN_USER, API_ERROR,
    LOGOUT_USER_SUCCESS, ADD_TO_PRIVATE_SELLER
} from "./actionsTypes";

const initialState = {
    error: '',
    loading: false,
    authUser: null,
    otpEmail: null,
    language: localStorage.getItem("i18nextLng"),
    privateSeller: null
}

const Login = (state = initialState , action: any) => {
    const {payload, type} = action;
    switch(type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            };
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                error: '',
                loading: false,
                authUser: payload || null
            };
            break;
        case LOGOUT_USER_SUCCESS:
            state = {
                ...state,
                error: '',
                loading: false,
                authUser: null
            };
            break;
        case ADD_TO_PRIVATE_SELLER: {
            const privateSeller:any = payload;
            state = {
                ...state,
                privateSeller
            };
        }
            break;
        case API_ERROR:
            state = { ...state, error: payload, loading: false };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
export default Login;