export const REQUEST_API = { 
  create: () => `/api/request/create`,
  fetch_all: () => `/api/request/getall`,
  fetch_by_id: (id: any) => `/api/request/${id}`,
  fetch_by_ref: (ref: any) => `/api/request-by-reference/${ref}`,
  fetch_all_ref: () => `/api/requests/all-ref`,
  delete: (id: any) => `/api/request/${id}`,
  update: (id: any) => `/api/updateRequest/${id}`,
  confirm: (refId: any) => `/api/confirmRequest/${refId}`,
  check_request_ref: (params:any) => `/api/checkRequest?${params}`
};
