import { sampol_bag, sampol_pant, sampol_shoes, sampol_sweter, sampol_top } from 'assets/image/image';
import { Modal, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

//apply base url for axios
const ASSET_URL = process.env.REACT_APP_ASSET_BASE_URL;

function PhotoRestrictionsModal(props:any) {
    const {onHide} = props;
    const { t } = useTranslation();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="hide-title" ></Modal.Header>
            <Modal.Body>


                <h5 className='text-center fw-bold mb-4'>{t('photo_modal.title')}</h5>
                <div className='modal-description-div'>
                    <div className='modal-description'>
                        <p>{t('photo_modal.first_rule')}</p>
                        <p>{t('photo_modal.second_rule')}</p>
                        <p>{t('photo_modal.third_rule')}</p>
                    </div>
                </div>
                <p className='text-center my-4'>{t('photo_modal.description')}</p>

                <div className='d-flex flex-wrap justify-content-center gap-3'>
                    <img src={`${ASSET_URL}${sampol_top}`} alt="top-img" />
                    <img src={`${ASSET_URL}${sampol_sweter}`} alt="sweter-img" />
                    <img src={`${ASSET_URL}${sampol_pant}`} alt="pant-img" />
                    <img src={`${ASSET_URL}${sampol_bag}`} alt="bag-img" />
                    <img src={`${ASSET_URL}${sampol_shoes}`} alt="shoes-img" />
                </div>

                <div className="text-center py-3">
                    <Button onClick={()=>onHide(true)} className="round-btn" variant="dark" size="sm">{t('photo_modal.btn')}</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PhotoRestrictionsModal;