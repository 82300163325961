import { Container, Card, Button } from "react-bootstrap";

// Start import images
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPrefixUrl } from 'helpers/common_helper';

const AcceptRequestPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const redirectMyRequest = () =>{
        navigate(`${getPrefixUrl()}/my-requests`);
    };
    return (
        <>
            <Container fluid className="background-white container-min-height">
                <Card.Title className="page-title text-uppercase pt-5">
                {t('accept_request.thanks')}
                </Card.Title>
                <div className="accept-request">
                    <p className="text-center my-5">
                        {t('accept_request.first_thanks_message')}
                        <br /><br />
                        {t('accept_request.sec_thanks_message')}
                        <br /><br />
                        {t('accept_request.third_thanks_message')}
                    </p>
                    <p className="text-center">
                        <Button onClick={() => redirectMyRequest()} variant="dark">{t('accept_request.back_to_overview')}</Button>
                    </p>
                </div>
            </Container>
        </>
    );
};

export default AcceptRequestPage;