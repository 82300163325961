import { ADD_TO_SHIPMENT, CLEAR_SHIPMENT } from "./actionsTypes";

const initialState = {
    shipment: {}
};
const Shipment = (state = initialState , action: any) => {
    const {payload, type} = action;
    switch(type) {
        case ADD_TO_SHIPMENT: {
            const shipment:any = {...state.shipment, payload};
            state = {
                ...state,
                shipment
            };
        }
            break;
        case CLEAR_SHIPMENT: {
            const shipment:any = {};
            state = {
                ...state,
                shipment
            };
        }
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};
export default Shipment;
