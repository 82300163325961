// Placeholder images : For male
import frontside_men_accessory from "assets/image/placeholder/Men/Acc-Front-M.jpg";
import logo_men_accessory from "assets/image/placeholder/Men/Acc-Logo-M.jpg";
import backside_men_bags from "assets/image/placeholder/Men/Bag-Back-M.jpg";
import bottom_men_bags from "assets/image/placeholder/Men/Bag-Bottom-M.jpg";
import frontside_men_bags from "assets/image/placeholder/Men/Bag-Front-M.jpg";
import logo_men_bags from "assets/image/placeholder/Men/Bag-Logo-M.jpg";
import inside_men_bags from "assets/image/placeholder/Men/Bags-Inside-M.jpg";
import frontside_men_bottoms from "assets/image/placeholder/Men/bottoms-Front-M.jpg";
import logo_men_bottoms from "assets/image/placeholder/Men/bottoms-Logo-M.jpg";
import frontside_men_jackets_coats from "assets/image/placeholder/Men/Jacket-Front-M.jpg";
import logo_men_jackets_coats from "assets/image/placeholder/Men/Jacket-Logo-M.jpg";
import inside_men_jackets_coats from "assets/image/placeholder/Men/Jackets-Inside-M.jpg";
import birds_eye_view_men_shoes from "assets/image/placeholder/Men/Shoes-Bird-M.jpg";
import side_view_men_shoes from "assets/image/placeholder/Men/Shoes-Side-M.jpg";
import sole_men_shoes from "assets/image/placeholder/Men/Shoes-Sole-M.jpg";
import logo_top_men_suits from "assets/image/placeholder/Men/Suit-Top-Logo-M.jpg";
import logo_bottom_men_suits from "assets/image/placeholder/Men/Suits-Bottom-M.jpg";
import frontside_bottom_men_suits from "assets/image/placeholder/Men/Suits-Front-Bottom-M.jpg";
import frontside_top_men_suits from "assets/image/placeholder/Men/Suits-Front-Top-M.jpg";
import frontside_men_tops from "assets/image/placeholder/Men/Top-Front-M.jpg";
import logo_men_tops from "assets/image/placeholder/Men/Top-Logo-M.jpg";

interface Obj {
  [key: string]: string;
}

const mensPlaceholderImages : Obj = {
  frontside_men_accessory,
  logo_men_accessory,
  backside_men_bags,
  bottom_men_bags,
  frontside_men_bags,
  logo_men_bags,
  inside_men_bags,
  frontside_men_bottoms,
  logo_men_bottoms,
  frontside_men_jackets_coats,
  inside_men_jackets_coats,
  logo_men_jackets_coats,
  birds_eye_view_men_shoes,
  side_view_men_shoes,
  sole_men_shoes,
  logo_top_men_suits,
  logo_bottom_men_suits,
  frontside_bottom_men_suits,
  frontside_top_men_suits,
  frontside_men_tops,
  logo_men_tops,
};

export default mensPlaceholderImages;
