/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { toastValues } from "common/toastValues";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Start import components
import ImageUploadLoader from "components/common/ImageUploadLoader";
import PhotoUploader from "components/common/PhotoUploader";

// Start import images

// Start import helpers
import { ARTICLE_API } from "constants/apiEndPoints/articleEndpoints";
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { FILE_API } from "constants/apiEndPoints/fileEndpoints";
import { del, post, put, putBnsApi } from "helpers/api_helpers";
import { getImagePlaceholder } from "helpers/common_helper";
import { useTranslation } from "react-i18next";

const AdditionalInfos = () => {
  //apply base url for axios
  const ASSET_URL = process.env.REACT_APP_ASSET_BASE_URL;
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    setUser(authUser);
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state }: any = useLocation();
  console.log({ state });

  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [article, setArticle] = useState<any>(state?.article || {});
  const [bnsArticle, setBnsArticle] = useState<any>(state?.bnsArticle || {});
  const [isCheckFormValidation, setIsCheckFormValidation] =
    useState<boolean>(false);
  const [images, setImages] = useState<any>([]);
  console.log({ images });

  useEffect(() => {
    const additionalImages = bnsArticle?.additional_infos || [];
    const existImages = bnsArticle?.images || null;
    if (additionalImages?.length) {
      // let result:any = additionalImages.map((info:any) => info.split('-')[1]);
      let result = additionalImages.map((info: any) => {
        // Remove "change-" prefix and replace underscores with spaces
        return info.replace(/^change-/, "");
      });

      if (result?.length) {
        let reqImages: any = [];
        result.forEach((img: any) => {
          reqImages.push({
            name: img,
            url: existImages[img],
            fileId: null,
            loading: false,
            required: true,
          });
        });

        setImages(reqImages);
      }
    }
  }, []);

  // Start events handler

  // Start img uplod events handler
  const onUploadImgHandler = async (file: any, field: any) => {
    initImageFieldContext({
      ...field,
      loading: true,
    });

    try {
      // Start file upload
      const blob = file.slice(0, file.size, "image/jpeg");
      const newFile = new File([blob], file.name, { type: "image/jpeg" });

      // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
      const formData = new FormData();
      formData.append("file", newFile);
      formData.append("requestUid", article?.requestUid);
      formData.append("articleUid", article?.uid);
      if (article?.userId) formData.append("userId", article?.userId);
      const { data } = await post(FILE_API.upload(), formData, {
        isFile: true,
      });

      if (data) {
        initImageFieldContext({
          ...field,
          url: data.publicUrl,
          fileId: data.id,
          loading: false,
        });
      }
    } catch (error) {
      initImageFieldContext({
        ...field,
        loading: false,
      });
      console.log("Error:", error);
    }
  };

  const initImageFieldContext = (fieldInfo: any) => {
    if (fieldInfo.name) {
      const imageFields = images.map((image: any) => {
        if (image.name === fieldInfo.name) {
          image = {
            ...image,
            ...fieldInfo,
          };
        }
        return image;
      });

      setImages(imageFields);
    }
  };

  const getPlaceholderImage = (fieldInfo: any) => {
    return getImagePlaceholder(fieldInfo, state?.article);
  };

  const onRemoveImgHandler = async (fileInfo: any) => {
    initImageFieldContext({
      ...fileInfo,
      url: null,
      fileId: null,
    });
  };

  const onSubmitPhotoHandler = async () => {
    if (isInavalidForm()) {
      try {
        const articleCode = state?.bnsArticle?.article_code;
        if (articleCode) {
          const updatedImages: any = getFormattedImageObjForBns();
          // update_addiona_images in buddy server
          const response = await putBnsApi(
            REQUEST_BNS_API.additional_images(articleCode),
            { images: updatedImages },
            { isArray: true }
          );

          if (response.status === "UPDATED") {
            toast.success("Image update Successfully!", toastValues);

            // update_addiona_images_local_server
            const { images, garbadgeImages }: any = getFormattedImageObj();
            await put(ARTICLE_API.update_articles_images(articleCode), {
              images: images || [],
            });
            await removeGarbadgeImages(garbadgeImages || []);
          } else {
            toast.error("Some things went wrong!", toastValues);
          }
        }
      } catch (error) {
        toast.error("Some things went wrong!", toastValues);
      }

      navigate(-1);
    } else {
      if (isCheckFormValidation) setIsCheckFormValidation(false);
      setTimeout(() => {
        setIsCheckFormValidation(true);
      });
    }
  };

  const isInavalidForm = () => {
    const requiredNullField = images.find(
      (img: any) => img.required && !img.url
    );
    if (requiredNullField && !requiredNullField.fileId) {
      return false;
    } else return true;
  };

  const onReturn = async () => {
    navigate(-1);
  };

  const getFormattedImageObjForBns = () => {
    // Transform the array into the desired format
    const transformedData: any = {
      images: { ...bnsArticle.images },
    };

    images.forEach((image: any) => {
      transformedData.images[image.name] = image.url;
    });

    return { ...bnsArticle.images, ...transformedData.images };
  };

  const getFormattedImageObj = () => {
    const existImages: any = article.images;
    let garbadgeImages: any = [];

    // Iterate through finalImages and update values based on the matching name
    const updatedImages = existImages.map((existImage: any) => {
      const matchingImage = images.find(
        (updatedImage: any) => updatedImage.name === existImage.name
      );

      if (matchingImage) {
        garbadgeImages.push(existImage);
        return {
          required: existImage.required,
          name: existImage.name,
          url: matchingImage.url,
          loading: matchingImage.loading,
          fileId: matchingImage.fileId,
        };
      } else {
        return existImage; // If no match found, keep the original values
      }
    });

    return {
      images: updatedImages || [],
      garbadgeImages: garbadgeImages || [],
    };
  };

  const removeGarbadgeImages = (garbadgeImages: any) => {
    //Start image delete loop here
    garbadgeImages.forEach(async (img: any) => {
      if (img?.fileId) {
        await del(FILE_API.delete(img?.fileId));
      }
    });
    //End image delete loop here
  };
  // End events handler

  return (
    <>
      <Container fluid className="background-white container-min-height">
        <Row>
          <Col lg={3} md={3} xs={12}>
            {" "}
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Card.Title className="page-title">
              {t("image_upload.title_add_photos")}
            </Card.Title>
            <Card.Subtitle className="text-center">
              {t("image_upload.image_upload_page_subtitle")}
            </Card.Subtitle>

            {/* Start photo */}
            <Row className="my-4">
              {images.map((image: any, index: number) => {
                return (
                  <Col
                    key={`${image.name}_${index}`}
                    lg={3}
                    md={4}
                    xs={6}
                    className="mb-4"
                  >
                    <div
                      className={`add-photo-box ${
                        isCheckFormValidation && image?.required && !image.url
                          ? "invalid"
                          : ""
                      }`}
                    >
                      {image?.loading ? (
                        <ImageUploadLoader height={130} width={130} />
                      ) : (
                        <img
                          className={`selected-img ${
                            image?.url ?? "opacity-75"
                          }`}
                          src={image?.url || getPlaceholderImage(image)}
                        />
                      )}
                      <div className="photo-box-title">
                        {t(image.name)}
                        {image.required ? (
                          <span className="form-required-icon">*</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <PhotoUploader
                        elId={image?.name}
                        isImageLoaded={image?.url}
                        selectedImage={(file: any) =>
                          onUploadImgHandler(file, image)
                        }
                        onRotted={() => console.log("rotted trigger")}
                        onReset={() => onRemoveImgHandler(image)}
                      />
                      <small className="invalid-text">
                        {t("form.invalid_message")}
                      </small>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {/* End Images      */}

            <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-between">
              <Button
                onClick={() => onReturn()}
                className="round-btn"
                variant="dark"
                size="sm"
              >
                {t("return")}
              </Button>
              <Button
                onClick={() => onSubmitPhotoHandler()}
                className="round-btn"
                variant="dark"
                size="sm"
              >
                {t("continue")}
              </Button>
            </div>
          </Col>
          <Col lg={3} md={3} xs={12}>
            {" "}
          </Col>
        </Row>
      </Container>

      {/* Start load components  */}
    </>
  );
};

export default AdditionalInfos;
