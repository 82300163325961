/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Card, Button, Spinner, Col, Row} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { v1 as uuidv1 } from 'uuid';
import { useTranslation } from 'react-i18next';

// Start import helpers
import { addToRequest, removeFromRequest, updateRequest, setPartnerParams, isReadRequestCondition, resetRequests } from 'store/request/actions';
import { actionConfirm, getCategoryName } from 'helpers/common_helper';
import { toastValues } from 'common/toastValues';
import { del, get, put, getBnsApi, post, postBnsApi } from 'helpers/api_helpers';
import { REQUEST_BNS_API } from 'constants/apiEndPoints/bnsApi/requestEndpoints';
import { REQUEST_API } from 'constants/apiEndPoints/requestEndpoints';
import { CATEGORY_API } from 'constants/apiEndPoints/categoriesEndpoints';

// Start import components
import Prompt from 'components/common/Prompt';
import ArticleCard from 'components/common/ArticleCard';
import { CART_API } from 'constants/apiEndPoints/cartEndpoints';
import { FILE_API } from 'constants/apiEndPoints/fileEndpoints';
import { requestUid } from 'store/request/actions';
import { getPrefixUrl } from 'helpers/common_helper';

const ArticlePage = () => {
    //load/instance of hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    //Instance variable
    const location = useLocation();
    const { requests, uid, isReadCondition, partnerInfo } = useSelector(({ Request }: { Request: any }) => Request);
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<any>({})
    const [isAuth, setIsAuth] = useState<boolean>(true);
    const [articles, setArticles] = useState<any>([]);
    const [mainCategories, setMainCategories] = useState<any>([]);

    //Start react effect life cycle
    useEffect(()=>{
        setUser(authUser);
        setIsAuth(!!authUser);
        fetchCategory();

        if(authUser?.id) {
            updateUnAuthArticles();
            getCartArticlesByUser();
        }
    }, [authUser]);

    useEffect(()=>{
        setArticles(requests);
    }, [requests]);

    //reqestid change handler
    const handleClick = () => {
        const newRequestId = uuidv1();
        if (uid) {
            dispatch(requestUid(newRequestId));
        }
    };
    //reqestid change handler

    //Start fetching handler/helpers
    const updateUnAuthArticles = async () => {
        const articleUids = requests?.filter((filterArticle:any) => !(filterArticle?.userId))?.map((mapArticle:any) => mapArticle.articleUid);
        
        if(articleUids.length > 0) {
            try {
                await put(CART_API.updateCartByArticleUids(), { articleUids });

                // Get articles after cart update 
                getCartArticlesByUser();

            } catch (error:any) {
                toast.error("Some things went wrong!", toastValues);
            }
        }
    };

    const getCartArticlesByUser = async () => {
        setLoading(true);

        try {
            let params:string = '';
            // const userId = authUser?.id;

            // if(userId) params += `userId=${userId}`;
            const responseCart = await get(CART_API.getAlls(params));

            if(responseCart.length > 0) {
                dispatch(resetRequests());

                responseCart.forEach((articleInfo:any)   => {
                    dispatch(addToRequest(articleInfo));
                });
            }
            setLoading(false);
        } catch (error:any) {
            toast.error("Some things went wrong!", toastValues);
            setLoading(false);
        }
    };

    const fetchCategory= async () => {
        setLoading(true);
        try {
            const { data } = await get(CATEGORY_API.fetch_all());
            setMainCategories(data.main_categories);
            setLoading(false);
        } catch (error:any) {
            toast.error(error.message, toastValues);
            setLoading(false);
        }
    };
    //End fetching handler/helpers

    //Start Events handler/helpers
    const onEdit = (id:string) => {
        navigate(`${getPrefixUrl()}/edit-article/${id}`)
    };

    const onRemove = async (requestArticle:any) => {
        const articleUid = requestArticle.articleUid;
        const config = {
            title:`${t('confirm_delete.are_you_sure')}`,
            text: `${t('confirm_delete.you_cannot_undo_this')}`,
            confirmButtonText: `${t('confirm_delete.confirm_button')}`,
            cancelButtonText: `${t('confirm_delete.interrupt_button')}`
        };

        const artcileImages = requestArticle?.images || [];

        // confirm modal
        actionConfirm(config).then( async ({isConfirmed}:any)=>{
            if(isConfirmed) {
                setLoading(true);
                try {

                    //Start image delete loop here
                    artcileImages.forEach(async (img:any) => {
                        if(img?.fileId) {
                            await del(FILE_API.delete(img?.fileId));
                        }
                    });
                    //End image delete loop here

                    const deleteSingleCart = await del(CART_API.delete(requestArticle.articleUid));
                    if(deleteSingleCart?.status === "true"){
                        dispatch(removeFromRequest(articleUid));
                        toast.success("Successfully Delete from Cart!", toastValues);
                        
                        if(authUser?.id) {
                            getCartArticlesByUser();
                        }
                    };
                    setLoading(false);

                } catch (error:any) {
                    toast.error("Some things went wrong!", toastValues);
                    setLoading(false);
                }
            }
        });
    };

    const sendRequest = async () => {
        if(authUser){
            setIsLoading(true);

            try {
                // const allArticles:any = [];
                if(requests.length) {
                    const allArticles = requests.map((article:any) => {
                        const transformedImages = article.images.reduce((acc:any, image:any) => {
                                acc[image.name] = image.url;
                                return acc;
                            }, {});

                        const categoryNames:any = getCategoryName(mainCategories, article, 'all');

                        return {
                          ...article,
                          main_category: categoryNames.mainCategoryName,
                          major_category: categoryNames.majorCategoryName,
                          minor_category: categoryNames.minorCategoryName,
                          images: transformedImages,
                        };
                      });
                      

                    const response = await postBnsApi(REQUEST_BNS_API.purchase_requests(), {"articles": allArticles});
                    if(response?.status === 'CREATED') {
                        const getArticles = await getBnsApi(REQUEST_BNS_API.fetch_request_by_id(response.data.reference_number));

                        requests.filter((article: any) => {
                            article.userId = user.id;
                            article.refId = response.data.reference_number;
                            // article.BnSId = response.data.id;
                            article.createdBy = user?.id;
                            article.updatedBy = user?.id;
                            return article;
                        });

                        const newAddedArticles:any = [];
                        if(requests.length) {
                            requests.forEach((articleInfo:any, i:any) => {

                                newAddedArticles.push({
                                    ...articleInfo,
                                    uid: articleInfo.articleUid,
                                    bnsArticleId: getArticles?.data?.articles[i]?.id,
                                    article_code: getArticles?.data?.articles[i]?.article_code,
                                    state: getArticles?.data?.articles[i]?.state,
                                    categoryNames: getArticles?.data?.articles[i]?.categoryNames,
                                    purchase_price: getArticles?.data?.articles[i]?.purchase_price,
                                });
                            })}

                        const newRequest = {
                            articles: newAddedArticles,
                            isReadCondition: isReadCondition,
                            refId: response.data.reference_number,
                            uid: uid,
                            state: getArticles?.data?.state,
                            createdBy: user?.id,
                            updatedBy: user?.id,
                            userId: user?.id,
                            partnerId: partnerInfo?.partnerId || null,
                            partnerCoupon: partnerInfo?.partnerCoupon || null,
                            actionLink: window.location.origin
                        };

                        const newRequestResponse:any = await post(REQUEST_API.create(), newRequest);

                        if (newRequestResponse?.id) {
                            toast.success("Successfully created!", toastValues);
                            // delete all cart from local server
                            await del(CART_API.allDelete());
                            dispatch(updateRequest([]));
                            dispatch(requestUid(''));
                            dispatch(isReadRequestCondition(false));
                            dispatch(setPartnerParams({}));
                            handleClick()
                        } else {
                            toast.error("Some things went wrong!", toastValues);
                        }

                        setIsLoading(false);
                        navigate(`${getPrefixUrl()}/welcome`);
                    } else {
                        toast.error(response.message, toastValues);
                    }

                }
            } catch (error:any) {
                setIsLoading(false);
                toast.error(error.message, toastValues);
            }

        } else {
            return navigate(`${getPrefixUrl()}/email-input`, {state:location})
        }
    }
    //End Events handler/helpers

    return (
        <>
            <Container fluid className="background-white container-min-height">
                <Card.Title className="page-title">{t('your_articles')}</Card.Title>

                {/* Start product category */}
                { loading? <div className="content-middle">
                    <Spinner animation="border" variant="warning" />
                </div> : <Row className="my-4 d-flex justify-content-center">
                    {
                        articles.map((article:any, index:number)=>{
                            return (<Col key={`${index}_${article.id}`} lg={3} md={6} xs={12}>
                                <ArticleCard onEdit={(onEdit)} onRemove={() => onRemove(article)} article={article} categories= { mainCategories } />
                            </Col>)
                        })
                    }
                </Row>
                }
                {/* End product category */}

                <Row className="mb-4">
                    <Col lg={12} md={12} xs={12}>
                        <div className="add-article-box" onClick={()=>navigate(`${getPrefixUrl()}/categories`)}>
                            <i className="fa fa-plus icon"></i>
                            <p>{t('add_article')}</p>
                        </div>
                    </Col>
                </Row>

                {
                    articles?.length? <>
                        <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-end">
                            {/* <Button onClick={() => navigate(-1)} className="round-btn" variant="dark" size="sm"> zurück </Button> */}
                            {authUser ? <Button className={`green-btn ${isLoading?'disabled':''}`} onClick={()=> isLoading? '':sendRequest()} variant="dark" size="sm">
                            {isLoading ? (
                                <><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> {t('sending')}...</>
                                ) : ( t('send_request_now') )}
                                </Button> :
                                <Button onClick={sendRequest} className="round-btn" variant="dark" size="sm"> {t('without_login')}</Button>}
                        </div>
                    </>:<></> 
                }

            </Container>
            
            {/* Start load components  */}
            { !isAuth? <Prompt />: '' }
        </>
    );
};

export default ArticlePage;
