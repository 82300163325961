import { ADD_TO_ADDRESS, CLEAR_ADDRESS} from './actionsTypes';

export const addToAddressList = (address: any) => {
  return {
    type: ADD_TO_ADDRESS,
    payload: address,
  };
};
export const clearAddress = () => {
  return {
    type: CLEAR_ADDRESS,
    payload: null,
  };
};
