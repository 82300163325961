import { ADD_TO_PAYOUT, CLEAR_PAYOUT} from './actionsTypes';

export const addToPayoutList = (payout: any) => {
  return {
    type: ADD_TO_PAYOUT,
    payload: payout,
  };
};
export const clearPayout = () => {
  return {
    type: CLEAR_PAYOUT,
    payload: null,
  };
};
