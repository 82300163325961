// Placeholder images : For female
import frontside_women_accessory from "assets/image/placeholder/Women/Acc-Front-W.jpg";
import logo_women_accessory from "assets/image/placeholder/Women/Acc-Logo-W.jpg";
import backside_women_bags from "assets/image/placeholder/Women/Bag-Back-W.jpg";
import bottom_women_bags from "assets/image/placeholder/Women/Bag-Bottom-W.jpg";
import frontside_women_bags from "assets/image/placeholder/Women/Bag-Front-W.jpg";
import inside_women_bags from "assets/image/placeholder/Women/Bag-Inside-W.jpg";
import logo_women_bags from "assets/image/placeholder/Women/Bag-Logo-W.jpg";
import frontside_women_jacket_coat from "assets/image/placeholder/Women/Jacket-Front-W.jpg";
import inside_women_jacket_coat from "assets/image/placeholder/Women/Jacket-Inside-W.jpg";
import logo_women_jacket_coat from "assets/image/placeholder/Women/Jacket-Logo-W.jpg";
import birds_eye_view_women_shoes from "assets/image/placeholder/Women/Shoes-Bird-W.jpg";
import sole_women_shoes from "assets/image/placeholder/Women/Shoes-Sole-W.jpg";
import side_view_women_shoes from "assets/image/placeholder/Women/Shoes-side-W.jpg";
import logo_bottom_women_suits from "assets/image/placeholder/Women/Suits-Bottom-Logo-W.jpg";
import frontside_bottom_women_suits from "assets/image/placeholder/Women/Suits-Front-W.jpg";
import logo_top_women_suits from "assets/image/placeholder/Women/Suits-Top-Logo-W.jpg";
import frontside_top_women_suits from "assets/image/placeholder/Women/Suits-front-Top-W.jpg";
import logo_women_tops from "assets/image/placeholder/Women/Top-Logo-W.jpg";
import frontside_women_tops from "assets/image/placeholder/Women/Tops-Front-W.jpg";
import frontside_women_bottoms from "assets/image/placeholder/Women/bottoms-Front-W.jpg";
import logo_women_bottoms from "assets/image/placeholder/Women/bottoms-Logo-W.jpg";
import heel_women_shoes from "assets/image/placeholder/Women/Shoes-Heels-W.jpg"
import frontside_women_dress from "assets/image/placeholder/Women/Dress-Front-W.jpg"
import logo_women_dress from "assets/image/placeholder/Women/Dress-Logo-W.jpg"

interface Obj {
  [key: string]: string;
}

const womenPlaceholderImages: Obj = {
  frontside_women_accessory,
  logo_women_accessory,
  backside_women_bags,
  bottom_women_bags,
  frontside_women_bags,
  logo_women_bags,
  inside_women_bags,
  frontside_women_bottoms,
  logo_women_bottoms,
  frontside_women_jacket_coat,
  inside_women_jacket_coat,
  logo_women_jacket_coat,
  birds_eye_view_women_shoes,
  side_view_women_shoes,
  sole_women_shoes,
  heel_women_shoes,
  logo_top_women_suits,
  logo_bottom_women_suits,
  frontside_bottom_women_suits,
  frontside_top_women_suits,
  frontside_women_tops,
  logo_women_tops,
  frontside_women_dress,
  logo_women_dress
};
export default womenPlaceholderImages;
