/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, Form, Button, Row, Col, Badge, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { actionConfirm, calculatePercentage } from "helpers/common_helper";
import ConfirmRequestModal from "components/common/ConfirmRequestModal";

// Start import helpers
import {getBnsApi, post, postBnsApi , putBnsApi, put} from 'helpers/api_helpers';
import { PAYOUT_API } from "constants/apiEndPoints/payoutEndpoints";
import { ADDRESS_BNS_API } from "constants/apiEndPoints/bnsApi/addressEndpoints";
import { ADDRESS_API } from "constants/apiEndPoints/addressEndpoints";
import { addToPayoutList } from "store/payout/actions";
import { addToAddressList } from "store/address/actions";
import { toastValues } from 'common/toastValues';
import { useTranslation } from "react-i18next";
import { PRIVATE_SELLER_API } from "constants/apiEndPoints/bnsPrivateSeller/privateSellerEndpoints";
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { REQUEST_API } from "constants/apiEndPoints/requestEndpoints";
import { addToShipment } from "store/shipment/actions";
import { SHIPMENT_API } from "constants/apiEndPoints/shipmentEndpoints";
import { getPrefixUrl } from "helpers/common_helper";
import {addToPrivateSeller} from "../store/auth/login/actions";

const ConfirmRequestPage = (props:any) => {
    let totalPrice = 0;
    const { goBack, goNext, request, coupon } = props;
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { payouts } = useSelector(({ Payout }: { Payout: any }) => Payout);
    const { address } = useSelector(({ Address }: { Address: any }) => Address);
    const { authUser, privateSeller } = useSelector(({ Login }: { Login: any }) => Login);
    const payoutValue = payouts?.payload || "";
    const [ user, setUser] = useState<any>({});
    const [isShowTermModal, setIsShowTermModal] = useState<boolean>(false);
    const [articles, setArticles] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isApiLoading, setIsApiLoading] = useState<boolean>(false);

    // payout start here
    const [paymentType, setPaymentType] = useState<string>(payoutValue?.paymentType || "bank");
    const [accountName, setAccountName] = useState<string>(payoutValue?.accountName || "");
    const [accountNumber, setAccountNumber] = useState<string>(payoutValue?.accountNumber || "");
    const [bankName, setBankName] = useState<string>(payoutValue?.bankName || "");
    const [bankSwift, setBankSwift] = useState<string>(payoutValue?.bankSwift || "");

    useEffect(() => {
        setUser(authUser);
        fetchBnsRequest();
    }, []);
    

    const fetchBnsRequest= async () => {
        setIsLoading(true);
        try {
            const { data } = await getBnsApi(REQUEST_BNS_API.fetch_request_by_id(state));
            const filteredArticles = data.articles.filter(
                (article:any) => article.state === 'confirmed');

            setArticles(filteredArticles || []);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error("Some things went wrong!", toastValues);
        }
    };

    const isChangedPrvtSellerInfo = () => {
        let result:any = false;
        if(privateSeller?.id) {
            if(
                address?.firstName !== privateSeller?.first_name
                || address?.lastName !== privateSeller?.name
                || address?.address !== privateSeller?.address_country
                || address?.location !== privateSeller?.address_city
                || `${address?.streetName} ${address?.streetNo}` !== privateSeller?.address_street
                || address?.postalCode !== privateSeller?.address_zipcode
                || accountName !== privateSeller?.bank_account_name
                || accountNumber !== privateSeller?.bank_iban
                || bankName !== privateSeller?.bank_name
                || bankSwift !== privateSeller?.bank_swift
                ) {
                result = true;
            }

        }
        return result;
    };

    const confirmRequestHandler  = async () => {
        const commonAddressData = {
            userId: authUser?.id,
            firstName: address?.firstName,
            lastName: address?.lastName,
            streetName: address.streetName,
            streetNo: address?.streetNo,
            postalCode: address?.postalCode,
            location: address?.location
        };

        const addressData = {
            address: address?.address,
            ...commonAddressData
        };

        const payoutData = {
            userId: user.id,
            status: "pending",
            paymentType: paymentType,
            accountName: accountName,
            accountNumber: accountNumber,
            bankName: bankName,
            bankSwift: bankSwift,
        };

        let upperCaseValue;
        if(paymentType === "bank"){
            upperCaseValue = "BANK_TRANSFER"
        } else {
            upperCaseValue = "VOUCHER"
        }

        setIsApiLoading(true);

        try {
   
            // Address data post part
            const responseAddressLocal = await post(ADDRESS_API.create(), addressData);

            if (responseAddressLocal.id) {
                dispatch(addToAddressList({}));
            }

            // Payout data post part
            const newPayout = await {
                ...payoutData,
                addressId: responseAddressLocal.id,
                requestRefId: request,
                privateSeller: privateSeller || ''
            };

            const responseOwnPayout:any = await post(PAYOUT_API.create(), newPayout);

            if (responseOwnPayout?.id) {
                dispatch(addToPayoutList({}));
            }

            const newShippingLabel:any = {
                email: authUser?.email,
                requestRefId: request,
                addressId: responseAddressLocal.id,
                payoutId: responseOwnPayout.id,
                ...commonAddressData
            };

            const shippingLabelResponse = await post(SHIPMENT_API.create_shipping_lavel(), newShippingLabel);
            dispatch(addToShipment(shippingLabelResponse));

            let resPrivateSeller:any = privateSeller;

            if(!(privateSeller?.id)) {
                //Private seller data for new create
                const privateSellerData = {
                    private_seller: {
                        first_name: address?.firstName,
                        name: address?.lastName,
                        address_street: `${address?.streetName} ${address?.streetNo}`,
                        address_zipcode: address?.postalCode,
                        address_city: address?.location,
                        email: user.email,
                        bank_account_name: accountName,
                        bank_iban: accountNumber,
                        bank_account_no: null,
                        bank_code: null,
                        bank_name: bankName,
                        bank_swift: bankSwift,
                        salutation: "Diverse",
                        title: null,
                        phone: null,
                        address_country: address?.address || null,
                        company: null,
                        campaign_code: coupon?.campaign_code || '372',
                        comment: null,
                        permission_newsletter_email: true,
                        partner_id: null,
                        phone_mobile: null,
                        address_country_iso: "DE",
                    }
                };
        
                // Address data Private Seller api server
                const resNewPrivateSeller = await postBnsApi(PRIVATE_SELLER_API.create(), privateSellerData);
                resPrivateSeller = resNewPrivateSeller?.private_seller;

            } else if(isChangedPrvtSellerInfo()){
                
                    const changedPrvtSellerInfo = {
                        private_seller: {
                            ...privateSeller,
                            first_name: address?.firstName,
                            name: address?.lastName,
                            address_street: `${address?.streetName} ${address?.streetNo}`,
                            address_zipcode: address?.postalCode,
                            address_city: address?.location,
                            address_country: address?.address,
                            email: user.email,
                            bank_account_name: accountName,
                            bank_iban: accountNumber,
                            bank_name: bankName,
                            bank_swift: bankSwift,
                        }
                    };                    

                // Address data Private Seller api server
                const updatePrivateSeller = await putBnsApi(PRIVATE_SELLER_API.update(privateSeller.id), changedPrvtSellerInfo);
                resPrivateSeller = updatePrivateSeller?.private_seller;
            }

            let purchaseRequest:any = {
                shipping_label: shippingLabelResponse?.labelUrl || "http://example.com",
                customer_email: user.email,
                private_seller_id: resPrivateSeller?.id?.toString() || '',
                payment_method: upperCaseValue,
                parcel_count: 1
            };

            if(paymentType === "coupon"){
               purchaseRequest = {
                    ...purchaseRequest,
                    coupon_code: coupon?.code || '',
                    coupon_name: coupon?.name || '',
                    coupon_description: coupon?.description || '',
                    partner_id: coupon?.partner_id || '',
                    campaign_code: coupon?.campaign_code || '372'
                };
            }

            // update_shipping_detail data put part
            const shipmentDetailResponse = await putBnsApi(ADDRESS_BNS_API.update_shipping_detail(state), { purchase_request: purchaseRequest });

            //Reset from store 
            dispatch(addToPrivateSeller(null));
            if (shipmentDetailResponse?.status === "UPDATED") {
                toast.success("Successfully created!", toastValues);
                dispatch(addToShipment({}));
                await put(REQUEST_API.confirm(state), purchaseRequest);

            } else {
                toast.error("Some things went wrong!", toastValues);
            }

            setIsApiLoading(false);

        } catch (error) {
            setIsApiLoading(false);
            toast.error("Some things went wrong!", toastValues);
        }

        navigate(`${getPrefixUrl()}/accept-request`);
    };

    const onRequestRemoveHandler = () => {
        const config = {
            title:`${t('confirm_delete.are_you_sure')}`,
            text: `${t('confirm_delete.you_cannot_undo_this')}`,
            confirmButtonText: `${t('confirm_delete.confirm_final_button')}`,
            cancelButtonText: `${t('confirm_delete.interrupt_final_button')}`
        };

        actionConfirm(config).then(({isConfirmed}:any)=>{
            if(isConfirmed) {
                // Here define remove api call
                setIsShowTermModal(!isShowTermModal);
            }
        });
    };

    const getTotalPrice = (totalPrice:any) => {
        //If payment type is coupon
        //Calculation and add benifit by coupon code
        if(paymentType === 'coupon' && coupon.code) {
            const couponPrice = calculatePercentage(totalPrice, coupon.value * 1, coupon.value_type);
            totalPrice = totalPrice + couponPrice;
        };
        
        const result = totalPrice.toFixed(2);
        return result || 0;
    };

    const getCouponBonus = (totalPrice:any) => {
        let result:any = calculatePercentage(totalPrice, coupon.value * 1, coupon.value_type) || 0;
        result = result.toFixed(2);

        return result || 0;
    }
    // End events handlers

    return (
        <>
            <>
                <Row className="confirm-request">
                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title>{t('your_articles')}</Card.Title>
                                { isLoading? <div className="content-middle-sm">
                                        <Spinner animation="border" variant="warning" />
                                    </div>:<>
                                        <Row className="request-details">
                                            {
                                                articles.map((data:any) => {
                                                    totalPrice += parseFloat(data.purchase_price);
                                                    return(
                                                        <div key={data.id}>
                                                            <Col>{data.name}</Col>
                                                            <Col className="text-end">{data.purchase_price || 0} {data.price} ,-</Col>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                (paymentType === 'coupon' && coupon.code)? 
                                                    <div>
                                                        <Col>{t('coupon_bonus')}</Col>
                                                        <Col className="text-end">{getCouponBonus(totalPrice)} ,-</Col>
                                                    </div>
                                            :<></>
                                            }
                                        </Row>
                                    </>
                                }
                                <Row>
                                    <Col>
                                        <strong>{t('in_total')}</strong>
                                    </Col>
                                    <Col className="text-end">
                                        <strong>{getTotalPrice(totalPrice)} ,-</strong>
                                    </Col>
                                </Row>
                                {/* <Button className="round-btn mt-3 ms-2" variant="dark" onClick={()=> goBack(0)}>{t('change_button')}</Button> */}
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title className="pb-2">{t('payout_method_confirm_request_page')}</Card.Title>
                                <div className="mx-auto payment-options justify-content-between">

                                    <Card className={`card-item ${paymentType === 'coupon'? 'active': ''}`}>
                                        <Card.Body>
                                            <Card.Title>
                                                <i className="fa-solid fa fa-desktop"></i>
                                                {paymentType === 'coupon'? <Badge className="default-badge checked-icon">
                                                    <i className="fa fa-check"></i>
                                                </Badge> : null}
                                            </Card.Title>
                                            <Card.Text className="text-uppercase">{t('coupon')}</Card.Text>
                                        </Card.Body>
                                    </Card>


                                    <Card className={`card-item ${paymentType === 'bank'? 'active': ''}`}>
                                        <Card.Body>
                                            <Card.Title>
                                                <i className="fa-solid fa-building-columns"></i>
                                                {paymentType === 'bank'? <Badge className="default-badge checked-icon">
                                                    <i className="fa fa-check"></i>
                                                </Badge>:<></>}
                                            </Card.Title>
                                            <Card.Text className="text-uppercase">{t('transfer')}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                { paymentType === 'bank'? <div>
                                    <Form.Group className="mb-3" controlId="formAccountName">
                                        <Form.Control disabled={true} type="text"
                                                      placeholder={`${t('account_owner')}`} value={accountName}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formAccountNumber">
                                        <Form.Control disabled={true} type="text" placeholder="IBAN" value={accountNumber}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBankName">
                                        <Form.Control disabled={true} type="text" placeholder="Bank Name" value={bankName}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBankSwift">
                                        <Form.Control disabled={true} type="text" placeholder="Bank Swift" value={bankSwift}/>
                                    </Form.Group>
                                </div>: <></> }
                                <Button className="round-btn mt-3 ms-2" variant="dark" onClick={()=> goBack(0)}>{t('change_button')}</Button>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title className="pb-2">{t('shipment')}</Card.Title>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="vorname">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('first_name')}`}
                                                          value={address?.firstName}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="nachname">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('last_name')}`}
                                                          value={address?.lastName}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="adresszusatz">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('address_supplement')}`}
                                                          value={address?.address}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="streetNane">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('street_name')}`}
                                                          value={address?.streetName}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="straße">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('street_no')}`}
                                                          value={address?.streetNo}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="postleitzahl">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('postal_code')}`}
                                                          value={address?.postalCode}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="ort">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('location')}`}
                                                          value={address?.location}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button className="round-btn mt-3 ms-2" variant="dark" onClick={()=> goBack(1)}>{t('change_button')}</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title className="text-center text-uppercase">
                                    {t('our_range')}
                                </Card.Title>
                                <Card.Title className="pb-2 text-center text-uppercase text-success">
                                    { getTotalPrice(totalPrice) } €*
                                </Card.Title>
                                <div className="text-center">
                                    <small> {t('our_range_description')}
                                    </small>
                                </div>
                                <div className="d-flex justify-content-between py-5"> 
                                    <Button className="round-btn" variant="outline-dark" onClick={() => onRequestRemoveHandler()}>{t('reject_button')}</Button>
                                    <Button className={`round-btn ${isApiLoading? 'disabled':''}`} variant="dark" onClick={() => isApiLoading?'':confirmRequestHandler()}>
                                        {isApiLoading ? (
                                         <><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> {t('loading')}...</>
                                        ) : ( t('confirm_delete.confirm_button') )}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>

            <ConfirmRequestModal
                id={state?.refId}
                show={isShowTermModal}
                onHide={() => setIsShowTermModal(!isShowTermModal) }
            />
        </>
    );
};

export default ConfirmRequestPage;