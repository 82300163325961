import { DEVICE } from "./actionsTypes";

const initialState = {
    isMobile: false,
    viewWidth: 0
}

const Device = (state = initialState , action: any) => {
    const {payload, type} = action;
    const viewWidth = payload?.width || 0; 
    let isMobile = false; 
     
    if(viewWidth < 991) {
        isMobile = true;
    }

    switch(type) {
        case DEVICE:
            state = {
                ...state,
                isMobile,
                viewWidth
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}
export default Device;