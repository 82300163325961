/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Container, Card, Button, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// Start import helpers
import { toastValues } from "common/toastValues";
import { getBnsApi, get, putBnsApi, put } from "helpers/api_helpers";
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { REQUEST_API } from "constants/apiEndPoints/requestEndpoints";
import { actionConfirm, getRequstStatus, getPrefixUrl } from "helpers/common_helper";

// Start import images
import {
  check_icon,
  cross_icon,
  reqProduct1,
  tick,
} from "assets/image/image";
import { useTranslation } from "react-i18next";
import { ARTICLE_API } from "constants/apiEndPoints/articleEndpoints";

//apply base url for axios
const ASSET_URL = process.env.REACT_APP_ASSET_BASE_URL;

const RequestDetailsPage = () => {
  const { id }: any = useParams();
  const [searchParams] = useSearchParams();
  const action = searchParams?.get("action");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [request, setRequest] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(true);
  const [bnsRequest, setBnsRequest] = useState<any>({});
  const [articles, setArticles] = useState<any>([]);
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [directHookAction, setDirectHookAction] = useState<any>("");


  useEffect(() => {
    setDirectHookAction(action);
  }, [authUser]);

  useEffect(() => {
    if (!authUser?.id && directHookAction === "status_change") {
      navigate(`${getPrefixUrl()}/login`, { state: { fromPathname: `/my-requests/${id}` } });
    }
  }, [directHookAction]);

  useEffect(() => {
    // if params is not define then redirect my requests page
    if (!id) {
      navigate(`${getPrefixUrl()}/my-requests`);
      return;
    }

    fetchRequestAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchBnsRequest();
    }
  }, [isAuthenticated]);

  const fetchBnsRequest = async () => {
    setIsLoading(true);
    try {
      const { data } = await getBnsApi(REQUEST_BNS_API.fetch_request_by_id(id));
      if (data.state === "offer_available") {
        setDisabled(false);
      }
      setBnsRequest(data || null);
      setArticles(data.articles || []);
      setTimeout(() => {
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      toast.error("Some things went wrong!", toastValues);
    }
  };

  const fetchRequestAuth = async () => {
    try {
      const paramsUrl = `refId=${id}`;
      const { message, success, request, articles } = await get(
          REQUEST_API.check_request_ref(paramsUrl)
      );

      if (success) {
        setRequest({...request, articles});
        setIsAuthenticated(success);
      } else {
        toast.error(message, toastValues);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message, toastValues);
    }
  };

  const handleReject = async (id: any) => {
    const config = {
      title: `${t("confirm_delete.are_you_sure")}`,
      text: `${t("confirm_delete.you_cannot_undo_this")}`,
      confirmButtonText: `${t("confirm_delete.confirm_button")}`,
      cancelButtonText: `${t("confirm_delete.interrupt_button")}`,
    };

    actionConfirm(config).then(({ isConfirmed }: any) => {
      if (isConfirmed) {
        // Here define remove api call
        const reject = async () => {
          try {
            const response = await putBnsApi(
                REQUEST_BNS_API.rejected_by_customer(id),
                {}
            );
            if (response.status === "UPDATED") {
              navigate(`${getPrefixUrl()}/my-requests`);
              toast.success("Successfully Rejected!", toastValues);
            }
            // update in local api
            await put(REQUEST_API.update(id), { status: "cancelled" });
          } catch (error) {
            toast.error("Some things went wrong!", toastValues);
          }
        };
        reject();
      }
    });
  };

  const onRequestRemoveHandler = (id: string) => {
    const config = {
      title: `${t("confirm_delete.are_you_sure")}`,
      text: `${t("confirm_delete.you_cannot_undo_this")}`,
      confirmButtonText: `${t("confirm_delete.confirm_button")}`,
      cancelButtonText: `${t("confirm_delete.interrupt_button")}`,
    };

    actionConfirm(config).then(({ isConfirmed }: any) => {
      if (isConfirmed) {
        // Here define remove api call
        const deleteArticle = async () => {
          try {
            const response = await putBnsApi(
                REQUEST_BNS_API.delete_article(id),
                {}
            );
            if (response.status === "UPDATED") {
              fetchBnsRequest();
              toast.success(response.message, toastValues);
            }
            await put(ARTICLE_API.update_articles(id), { state: "cancelled" });
          } catch (error) {
            toast.error("Some things went wrong!", toastValues);
          }
        };
        deleteArticle();
      }
    });
  };

  const showPhotos = (bnsArticle: any) => {
    const articles = request?.articles; 
    let article = {}
    articles.forEach((item:any)=>{
      if(item.article_code === bnsArticle.article_code) { article = item; }
    });

    const stateConfig = {
      reference_number: id,
      isEdit: true,
      bnsArticle,
      article,
      fromPathname: `/my-requests/${id}`,
    };

    navigate(`${getPrefixUrl()}/additional-infos`, { state: stateConfig });
  };

  const showPayout = () => {
    navigate(`${getPrefixUrl()}/payout`, { state: bnsRequest.reference_number });
  };

  const isRejectDisabled = () => {
    const disabledItems = ['offer_accepted', 'rejected', 'purchased', 'returned', 'completed'];
    return disabledItems.includes(bnsRequest?.state);
  };

  const getFirstImage = (images:any) => {
    let url = ASSET_URL + reqProduct1;
    for (const key in images || {}) {
      if (images.hasOwnProperty(key)) {
        const value = images[key];
        if(value) return value;
      }
    }

    return url;
  };

  const isHideRemoveBtn = (articleState:any) => {
    return !!(['offer_accepted', 'rejected', 'returned', 'purchased'].includes(articleState));
  }

  // End events handlers

  return (
      <>
        <Container fluid className="background-white container-min-height">
          <Card.Title className="page-title text-uppercase mb-2">
            {t("article")}
          </Card.Title>
          <Row>
            <Col lg={9} md={8} xs={12}>
              <div className="request-list">
                {isLoading ? (
                    <div className="content-middle">
                      <Spinner animation="border" variant="warning" />
                    </div>
                ) : (
                    <>
                      {articles.length < 0 ? (
                          <div className="content-middle">
                            {t("find_nothing_message")}
                          </div>
                      ) : (
                          <Row className="d-flex justify-content-center">
                            {articles.map((article: any, index: number) => {
                              return article.state === "cancelled" ||
                              article.state === "rejected_by_customer" ? null : (
                                  <Col lg={4} md={6} xs={12} key={index}>
                                    <Card
                                        className="request-product"
                                        style={{ minWidth: "14rem" }}
                                    >
                                      <Card.Body>
                                        <div className="product-info">
                                          <div>
                                            <div>{article.category}</div>
                                            <div>{article.name}</div>
                                            <div>
                                              {article.major_category} {" "}
                                              {article.minor_category}{" "}
                                            </div>
                                            {
                                              article.state === "confirmed"
                                                  ?
                                                  <div>{t("price")} {article.purchase_price || 0} €</div>
                                                  : null
                                            }
                                            <div>{article.userEmail}</div>
                                          </div>
                                          <div className="how-to-img-box icon-position mr-10">
                                            {article.state === "rejected" ? (
                                                <img
                                                    className="cross-icon"
                                                    src={cross_icon}
                                                    title="cross-icon"
                                                />
                                            ) : article.state === "offer_accepted" ? (
                                                <img
                                                    className="check_icon"
                                                    src={check_icon}
                                                    title="check_icon"
                                                />
                                            ) : null}

                                            <img src={getFirstImage(article?.images)} className="product-info-img" />
                                          </div>
                                        </div>
                                        <div>
                                          { article.additional_infos.length === 0
                                              ?
                                              (article.state === "additional_infos" ? (
                                                      <p className="req-state-red">
                                                        {t("err_message.need_new_pictures")}
                                                      </p>)
                                                  :
                                                  article.state ===
                                                  "waiting_for_customer" ? (
                                                          <p className="req-state-red">
                                                            {t("err_message.waiting_for_customer")}
                                                          </p>)
                                                      :
                                                      article.state ===
                                                      "waiting_for_approval" ? (
                                                              <p className="req-state-black">
                                                                {t("err_message.waiting_for_approval")}
                                                              </p>
                                                          )
                                                          :
                                                          article.state === "offer_accepted" ? (
                                                                  <p className="req-state-white">
                                                                    <img
                                                                        className="click-icon"
                                                                        src={tick}
                                                                        title="tick-icon"
                                                                    />{" "}
                                                                    {t("err_message.offer-accepted")}
                                                                  </p>
                                                              )
                                                              :
                                                              article.state === "open" ? (
                                                                      <p className="req-state-gray">
                                                                        {t("err_message.open")}
                                                                      </p>
                                                                  )
                                                                  :
                                                                  article.state === "confirmed" ? (
                                                                          <p className="req-state-white">
                                                                            {t("err_message.confirmed")}
                                                                          </p>
                                                                      )
                                                                      :
                                                                      article.state === "purchased" ? (
                                                                            <p className="req-state-white">
                                                                              {t("err_message.purchased")}
                                                                            </p>
                                                                          )
                                                                      :
                                                                      article.state === "returned" ? (
                                                                            <p className="req-state-red">
                                                                              {t("err_message.returned")}
                                                                            </p>
                                                                          )
                                                                      :
                                                                      article.state === "rejected" ? (
                                                                              <p className="req-state-red">
                                                                                {article.rejection_reason}
                                                                              </p>
                                                                          )
                                                                          :
                                                                          (
                                                                              <p className="req-state-red">
                                                                                {t("err_message.not_defined")}
                                                                              </p>
                                                                          ))
                                              :
                                              (
                                                  <p className="req-state-red">
                                                    {t("err_message.need_new_pictures")}
                                                  </p>
                                              )
                                          }
                                        </div>
                                        <div>
                                          {article.additional_infos.length === 0 ||
                                          article.state === "open" ||
                                          article.state === "waiting_for_approval" ||
                                          article.state === "confirmed" ? null : (
                                              <Button
                                                  className="mr-10"
                                                  variant="dark"
                                                  onClick={() => showPhotos(article)}
                                              >
                                                {t("beheben_button")}
                                              </Button>
                                          )}
                                          { isHideRemoveBtn(article.state)? null :
                                              <div
                                                  className="round-btn btn btn-sm label-round-btn"
                                                  onClick={() =>
                                                      onRequestRemoveHandler(
                                                          article.article_code
                                                      )
                                                  }
                                              >
                                                {t("removed_button")}
                                              </div>
                                          }
                                          {article.reject_reason ? (
                                              <Button variant="outline-dark">
                                                {t("removed_button")}
                                              </Button>
                                          ) : null}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                              );
                            })}
                          </Row>
                      )}
                    </>
                )}
              </div>
            </Col>
            <Col lg={3} md={4} xs={12} className="d-flex justify-content-center">
              {!!bnsRequest ? (
                  <>
                    <div className="our-request-price d-flex justify-content-center align-items-center">
                      <div className="p-3">
                        <h3 className="text-success text-black ">
                          {t("our_range")}
                        </h3>
                        <h2 className="text-success fw-bold">
                          {bnsRequest.total_purchase_price || 0} €
                        </h2>
                      </div>
                    </div>
                  </>
              ) : (
                  ""
              )}
            </Col>
          </Row>
          {!!bnsRequest ? (
              <>
              <Row>
                <Col lg={12} md={12} xs={12}>
                {/* <div className="text-center">
                <small>
                  *Dieser Preis ist unverbindlich und es kann nachträglich zu
                  Abweichungen kommen. Weitere Infos
                </small>
              </div> */}
                <div className="d-flex justify-content-between py-5">
                  <Button
                      disabled={isRejectDisabled()}
                      onClick={() => handleReject(bnsRequest?.reference_number)}
                      variant="outline-dark"
                  >
                    {t("reject_button")}
                  </Button>
                  <Button
                      disabled={disabled}
                      variant="dark"
                      onClick={() => showPayout()}
                  >
                    {t("accept_button")}
                  </Button>
                </div>
                </Col>
              </Row>
              </>
          ) : (
              ""
          )}
        </Container>
      </>
  );
};

export default RequestDetailsPage;
