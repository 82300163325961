// Authentication related pages
import Landing from "pages/LandingPage";
import LoginPage from "pages/LoginPage";
import RegistrationPage from "pages/RegistrationPage";
import EmailInputPage from "pages/EmailInputPage";
import GuidelinePage from "pages/GuidelinePage";
import CategoryPage from "pages/CategoryPage";
import PhotoPage from "pages/PhotoPage";
import ArticlePage from "pages/ArticlePage";
import OTPVerifyPage from "pages/OTPVerifyPage";
import WelcomePage from "pages/WelcomePage";
import MyRequestsPage from "pages/MyRequestsPage";
import RequestDetailsPage from "pages/RequestDetailsPage";
import PayoutPage from "pages/PayoutPage";
import ShippingPage from "pages/ShippingPage";
import ConfirmRequestPage from "pages/ConfirmRequestPage";
import AcceptRequestPage from "pages/AcceptRequestPage";
import EditArticle from "pages/EditArticle";
import AdditionalInfos from "pages/AdditionalInfos";

// Sectional dev Routes
import { testRoutes } from "./testRoutes";

const languageCodes = [ '', 'de', 'en', 'it', 'pl'];
const prefixUrl = '/send';

//Start routes for private
const privateRoutes:any = [{ path: "/", element: <Landing /> }];
const pRoutes:any = [
  ...testRoutes,
  { path: "/", element: <Landing /> },
  { path: "/my-requests", element: <MyRequestsPage /> },
  { path: "/my-requests/:id", element: <RequestDetailsPage /> },
  { path: "/payout", element: <PayoutPage /> },
  { path: "/shipping", element: <ShippingPage /> },
  { path: "/confirm-request", element: <ConfirmRequestPage /> },
  { path: "/accept-request", element: <AcceptRequestPage /> },
  { path: "/welcome", element: <WelcomePage /> },
  { path: "/guideline", element: <GuidelinePage /> },
  { path: "/categories", element: <CategoryPage /> },
  { path: "/photo", element: <PhotoPage /> },
  { path: "/your-article", element: <ArticlePage /> },
  { path: "/edit-article/:id", element: <EditArticle /> },
  { path: "/additional-infos", element: <AdditionalInfos /> },
];

//Create multi public rotues
languageCodes.forEach((lang:any, index:any)=>{
  pRoutes.forEach((pRoute:any, pIndex:any)=>{
    privateRoutes.push({
      path: `${lang}${prefixUrl}${pRoute.path}`,
      element: pRoute.element
    })
  })
});

//all routes will be here
const authProtectedRoutes = [...privateRoutes];

//Start routes for private
const publicRoutes:any = [{ path: "/", element: <Landing /> }];
const pubRoutes = [
  { path: "/", element: <Landing /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/registration", element: <RegistrationPage /> },
  { path: "/email-input", element: <EmailInputPage /> },
  { path: "/guideline", element: <GuidelinePage /> },
  { path: "/categories", element: <CategoryPage /> },
  { path: "/photo", element: <PhotoPage /> },
  { path: "/your-article", element: <ArticlePage /> },
  { path: "/otp-verify", element: <OTPVerifyPage /> },
  { path: "/edit-article/:id", element: <EditArticle /> },
  { path: "/my-requests/:id", element: <RequestDetailsPage /> }
];
//Create multi public rotues
languageCodes.forEach((lang:any, index:any)=>{
  pubRoutes.forEach((pubRoute:any, pIndex:any)=>{
    publicRoutes.push({
      path: `${lang}${prefixUrl}${pubRoute.path}`,
      element: pubRoute.element
    })
  })
});

//Return all routes
export { privateRoutes, authProtectedRoutes, publicRoutes };
