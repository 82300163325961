/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Button, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

// Start import images
import {addToAddressList, clearAddress} from "../store/address/actions";
const ShippingPage = (props:any) => {
    const { goBack, goNext } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
   
    const { privateSeller } = useSelector(({ Login }: { Login: any }) => Login);
    const addressState = useSelector(({ Address }: { Address: any }) => Address);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [streetName, setStreetName] = useState<string>("");
    const [streetNo, setStreetNo] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [invalidPoslCode, setInvalidPoslCode] = useState<boolean>(false);

    useEffect(() => {
       const addressPayload = addressState?.address || {};


        if(addressPayload?.address || privateSeller?.address_country) {
            setAddress(addressPayload?.address || privateSeller?.address_country);
        }

        if(addressPayload?.firstName || privateSeller?.first_name) {
            setFirstName(addressPayload?.firstName || privateSeller?.first_name);
        }

        if(addressPayload?.lastName || privateSeller?.name) {
            setLastName(addressPayload?.lastName || privateSeller?.name)
        };

        if(addressPayload?.postalCode || privateSeller?.address_zipcode) {
            setPostalCode(addressPayload?.postalCode || privateSeller?.address_zipcode);
        }

        if(addressPayload?.location || privateSeller?.address_city) {
            setLocation(addressPayload?.location || privateSeller?.address_city);
        }

        let privateSStreetName:any = null;
        let privateSStreetNo:any = null;
        if(privateSeller?.address_street) {
            // Use a regular expression to match the street name and number
            const match:any = privateSeller?.address_street.match(/^(.*?)(\s+\S+)$/);

            // Extract the street name and street number
            const streetName:any = match ? match[1] : "";
            const streetNumber = match ? match[2].trim() : "";

            privateSStreetName = streetName.trim();
            privateSStreetNo = streetNumber;
        }

        if(addressPayload?.streetName || privateSStreetName) {
            setStreetName(addressPayload?.streetName || privateSStreetName);
        }

        if(addressPayload?.streetNo || privateSStreetNo) {
            setStreetNo(addressPayload?.streetNo || privateSStreetNo);
        }

    }, []);

    const addAddress = async () => {
        const addressData = {
            firstName: firstName,
            lastName: lastName,
            address: address,
            streetName: streetName,
            streetNo: streetNo,
            postalCode: postalCode,
            location: location
        };

        dispatch(clearAddress());
        dispatch(addToAddressList(addressData));
        goNext(2);
    };

    const handlePostalCodeChange = (e:any) => {
        const fieldValue = e.target.value;
        setPostalCode(fieldValue);
        setInvalidPoslCode(true);
        if( fieldValue && /^\d{5}$/.test(fieldValue)) {
            setInvalidPoslCode(false);
        }
    };

    const isInvalidForm = () => {
        return !(firstName && streetName && streetNo && !invalidPoslCode && location);  
    }
    // End events handlers

    return (
        <>
            <Row>
                <Col lg={3} md={3} xs={12}> </Col>
                <Col lg={6} md={6} xs={12}>
                    <div className="text-center pb-5">{t('shipment_details')}</div>
                    <div className="shipping">
                        <div>
                            <Form>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="vorname">
                                            <Form.Control type="text" 
                                            placeholder={`${t('first_name')}`}                   
                                            value={firstName}
                                            onChange={(e)=>setFirstName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="nachname">
                                            <Form.Control type="text" 
                                            placeholder={`${t('last_name')}`}
                                                            value={lastName}
                                                            onChange={(e)=>setLastName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="adresszusatz">
                                            <Form.Control type="text" 
                                            placeholder={`${t('address_supplement')}`}
                                                            value={address}
                                                            onChange={(e)=>setAddress(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="sreetName">
                                            <Form.Control type="text" 
                                            placeholder={`${t('street_name')}`}
                                                            value={streetName}
                                                            onChange={(e)=>setStreetName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="straße">
                                            <Form.Control type="text" 
                                            placeholder={`${t('street_no')}`}
                                                            value={streetNo}
                                                            onChange={(e)=>setStreetNo(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className={`${invalidPoslCode?'invalid':''}`}>
                                            <Form.Control
                                                type="number"
                                                value={postalCode}
                                                placeholder={`${t('postal_code')}`}
                                                onChange={(e:any)=>handlePostalCodeChange(e)}
                                            />
                                            <small className="invalid-text">{t('form.postal_code.validation_message')}</small>
                                            
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="ort">
                                            <Form.Control type="text" 
                                            placeholder={`${t('location')}`}
                                                            value={location}
                                                            onChange={(e)=>setLocation(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-between py-2">
                                            <Button className="round-btn" variant="outline-dark" onClick={() => goBack(0)}>{t('return')}</Button>
                                            <Button disabled={isInvalidForm()} className="round-btn" variant="dark" onClick={() => addAddress()}>{t('continue')}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} xs={12}> </Col>
            </Row>
        </>
    );
};

export default ShippingPage;