export const ADD_TO_REQUEST = 'ADD_TO_REQUEST';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const REMOVE_FROM_REQUEST = 'REMOVE_FROM_REQUEST';
export const SELECT_REQUEST = 'SELECT_REQUEST';
export const UPDATE_SELECT_REQUEST = 'UPDATE_SELECT_REQUEST';
export const CLEAR_SELECT_REQUEST = 'CLEAR_SELECT_REQUEST';
export const IS_READ_CONDITION = 'IS_READ_CONDITION';
export const REQUEST_UID = 'REQUEST_UID';
export const SET_PARTNER_DATA = 'SET_PARTNER_DATA';
export const RESET_REQUESTS = 'RESET_REQUESTS';
