import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Prompt = (props:any) => {
  const { t } = useTranslation();
  
  useEffect(()=>{
    window.addEventListener('beforeunload', handleBeforeUnload);

    // 👇️ remove the event listener when the component unmounts
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  const handleBeforeUnload = (e:any) => {
    const confirmationMessage = t('message.prompt');
    // Display the confirmation message
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  return (
    <>
  
    </>
  );
};

export default Prompt;
