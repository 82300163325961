import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationENG from './locales/en/translation.json';
import translationDe from './locales/de/translation.json';
import translationIt from './locales/it/translation.json';
import translationPl from './locales/pl/translation.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: localStorage.getItem('i18nextLng') || 'de',
    fallbackLng: 'de', // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    resources: {
      de: {
        translation: translationDe
      },
      en: {
        translation: translationENG
      },
      it: {
        translation: translationIt
      },
      pl: {
        translation: translationPl
      }
    }
  });

export default i18n;
