// Start import helpers
import {isEmbadedInWP } from 'helpers/common_helper';
import { 
  LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER,
  LOGOUT_USER_SUCCESS, API_ERROR, ADD_TO_PRIVATE_SELLER
} from './actionsTypes';

export const loginUser = (user: any, navigate: any) => {
  setSendAuth('login', user);
  return {
    type: LOGIN_USER,
    payload: { user, navigate }
  };
};

export const loginSuccess = (user: any) => {
  setSendAuth('login', user);
  return {
    type: LOGIN_SUCCESS,
    payload: user
  };
};

export const logoutUser = (navigate: any) => {
  localStorage.removeItem('authUser');
  setSendAuth('logout', '');
  navigate('/');
  navigate(0);
  return {
    type: LOGOUT_USER,
    payload: navigate
  };
};

export const logoutUserSuccess = () => {
  localStorage.removeItem('authUser');
  setSendAuth('logout', '');
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  };
};

export const addToPrivateSeller = (privateSeller:any) => {
  localStorage.setItem('privateSeller', privateSeller);
  return {
    type: ADD_TO_PRIVATE_SELLER,
    payload: privateSeller
  };
};

export const apiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error
  };
};

// It's for wordpress site auth 
const setSendAuth = (action:string, user:any) => {
  if(isEmbadedInWP()) {
    if(action === 'login' && user?.id) {
      localStorage.setItem('sendAuth', JSON.stringify({...user, isLoggedIn: true}));
    } else {
      localStorage.removeItem('sendAuth');
    }
  }
}