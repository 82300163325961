import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function TermConditionModal(props:any) {
    const {onHide} = props;
    const { t, i18n } = useTranslation();

    // Start events handlers

    // End events handlers

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="hide-title" closeButton></Modal.Header>
            <Modal.Body>
                {t('term_conditions.terms_and_conditions_sub_title')}

                <br/>
                {t('term_conditions.terms_and_conditions_general_provisions')}
                <br/>

                {t('term_conditions.terms_and_conditions_general_provisions_des')}
                <br/>

                {t('term_conditions.terms_and_conditions_acceptance_policy')}<br/>

                {t('term_conditions.terms_and_conditions_acceptance_policy_des')}
                <br/>

                {t('term_conditions.terms_and_conditions_check_by_the_buyer')}<br/>

                {t('term_conditions.terms_and_conditions_check_by_the_buyer_des')}
                <br/>

                {t('term_conditions.terms_and_conditions_no_obligation_to_purchase')}<br/>

                {t('term_conditions.terms_and_conditions_no_obligation_to_purchase_des')}
                <br/>

                {t('term_conditions.terms_and_conditions_no_rescission_after_purchase')}<br/>

                {t('term_conditions.terms_and_conditions_no_rescission_after_purchase_des')}
                <br/>

                {t('term_conditions.terms_and_conditions_return_collection')}<br/>

                {t('term_conditions.terms_and_conditions_return_collection_des')}
                <br/>

                {t('term_conditions.terms_and_conditions_non_collection_failed_return')}<br/>

                {t('term_conditions.terms_and_conditions_non_collection_failed_return_des')}
                <br/>

                {t('term_conditions.terms_and_conditions_prices')}<br/>


                {t('term_conditions.terms_and_conditions_prices_des')}
                <br/>


                {t('term_conditions.terms_and_conditions_storage_of_relevant_contact_data')}<br/>


                {t('term_conditions.terms_and_conditions_storage_of_relevant_contact_data_des')}
                <br/>


                {t('term_conditions.terms_and_conditions_liability')}<br/>


                {t('term_conditions.terms_and_conditions_liability_des')}
                <br/>


                {t('term_conditions.terms_and_conditions_invalid_clauses')}<br/>


                {t('term_conditions.terms_and_conditions_invalid_clauses_des')}
                <br/>


                {t('term_conditions.terms_and_conditions_place_of_performance')}<br/>


                {t('term_conditions.terms_and_conditions_place_of_performance_des')}
                <br/>

                <div className="text-center py-3">
                    <Button onClick={()=>onHide(true)} className="round-btn" variant="dark" size="sm"> {t('term_conditions.terms_and_conditions_modal_confirm_button')} </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default TermConditionModal;