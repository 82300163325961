const requestReviewState = {
    reviewRequests: [],
};

const RequestReview = (state = requestReviewState, action: any) => {
    const { payload, type } = action;
    // switch (type) {
    //     case IS_READ_CONDITION:
    //         state = {
    //             ...state,
    //             isReadCondition: payload,
    //         };
    //         break;
    //     default:
    //         state = { ...state };
    //         break;
    // }
    return state;
}
export default RequestReview;
