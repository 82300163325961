import { call, put, takeEvery } from "redux-saga/effects";
import { userForgetPasswordSuccess } from "./actions";
import { FORGET_PASSWORD } from "./actionsTypes";
import { forgetPasswordAPI } from "./apiCalls";

function* forgetPassword({payload: {user , history , navigate }}:any):any{
    try{
        const response = yield call(forgetPasswordAPI, {
            emailId: user,
            url: history
        });
        console.log(response);

        yield put(userForgetPasswordSuccess(response.message))
        navigate("/login");
        
    }catch(error: any)
    {
        console.log(error);
    }
  

}
function* ForgetSaga(){
    yield takeEvery(FORGET_PASSWORD, forgetPassword)
}
export default ForgetSaga;