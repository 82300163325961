import { combineReducers } from 'redux';

// Authentication
// import ForgetPassword from './auth/forgetpassword/reducer';
// import ResetPassword from './auth/resetpassword/reducer';
import Login from './auth/login/reducer';
import Device from './auth/device/reducer';
import Request from './request/reducer';
import RequestReview from './requestReview/reducer';
import Payout from './payout/reducer';
import Shipment from './shipment/reducer';
import Address from './address/reducer';

const rootReducer = combineReducers({
  Login,
  // ForgetPassword,
  // ResetPassword,
  Device,
  Request,
  RequestReview,
  Payout,
  Address,
  Shipment
});

export default rootReducer;
