/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
// Start Import package
import Card from 'react-bootstrap/Card';

// Start import helpers
import {getCategoryName } from 'helpers/common_helper';

// Start import images
import {
  photoPlaceholderFront,
  closeIcon,
  penIcon,
} from 'assets/image/image';
import { useEffect, useState } from 'react';

const ArticleCard = (props:any) => {
  const { article, onRemove, onEdit, categories } = props;
  const [imageCount, setImageCount] = useState(0);
  const [firstImage, setFirstImage] = useState(null);
  const [brandName, setBrandName] = useState<any>('');
  const [mainCategoryName, setMainCategoryName] = useState<any>('');
  const [majorCategoryName, setMajorCategoryName] = useState<any>('');

  useEffect(()=>{
    // setDefaultImage(article.front_image);
    setBrandName(article.brand);
    const categoryNames:any = getCategoryName(categories, article, 'all');
    setMainCategoryName(categoryNames.mainCategoryName || article.main_category);
    setMajorCategoryName(categoryNames.majorCategoryName || article.major_category);
  
    if(article?.images) {
      let count = 0;
      let fImage:any = null;

      article?.images.forEach((image:any) => {
        if(image?.url){
          count++;
          if(!fImage) fImage = image?.url;
        }
      })

      setFirstImage(fImage);
      setImageCount(count);
    }

  }, [article]);

  return (
      <Card className="article-card">
        <Card.Body>
          <div className="article-card-left">
            <Card.Title>{brandName}</Card.Title>
            <Card.Text> {mainCategoryName} /  {majorCategoryName} </Card.Text>
            <div className="article-actions">
              <img className="article-action-btn" src={penIcon} title="Rotted" onClick={()=>onEdit(article.articleUid)} />
              <img className="article-action-btn" src={closeIcon} title="Remove" onClick={()=>onRemove(article.articleUid)} />
            </div>
          </div>
          <div className="card-right image-div">
            <img src={firstImage || photoPlaceholderFront } />
            <div className="img-count-div">{imageCount}</div>
          </div>

        </Card.Body>
      </Card>
  );
};

export default ArticleCard;
