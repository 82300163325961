import { Container, Card, Button, Form, Col, Row} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState , FormEvent} from 'react';
import { toast } from 'react-toastify';
import { google, facebook, apple } from "assets/image/image";
import { useTranslation } from 'react-i18next';

// Start helpers
import { isEmailValid } from 'common/validations';
import { toastValues } from 'common/toastValues';
import { post } from 'helpers/api_helpers';
import { AUTH_API } from 'constants/apiEndPoints/securityEndPoints';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isOTPEmailInvalid, setIsOTPEmailInvalid] = useState<boolean>(true);
  const [OTPEmail, setOTPEmail] = useState<string>('');

  const onNavigatehandler =(e: FormEvent<EventTarget>, path: string): void => {
    if(path) navigate(path, { state });
  };
// Start events handler
  const otpEmailHandler = (e: FormEvent<EventTarget>): void => {
    let target = e.target as HTMLInputElement;
    const value = target.value;
    setOTPEmail(value);

    setIsOTPEmailInvalid(!isEmailValid(value));
  };

  const sendOTPFormHandler = async () => {
    if(!OTPEmail) {
      toast.error('Please, Enter your email!', toastValues);
    }
    try {
      if (OTPEmail && !isOTPEmailInvalid) {
        const formData = {
          email: OTPEmail,
          type: 'registration'
        };
        const res = await post(AUTH_API.sendOtp(), formData);
        if(res) {
          toast.success('Successfully sent. Please check your email!', toastValues);
          navigate('/otp-verify',{ state: {...state, email: OTPEmail } });
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.title, toastValues);
    }
  };
// End events handler

  return (
      <>
        <Container fluid className="background-white container-min-height">
          <Card.Title className="page-title fw-bold">{t('login')}</Card.Title>

          {/* Start product category */}
          {/* <Row className="mb-4">
            <Col lg={4} md={3} xs={12}></Col>
            <Col lg={4} md={6} xs={12}>
              <button className="google_login_btn mb-3"> <img src={google} width={25} height={25} /> {t('login_with_google')}</button>
              <button className="apple_login_btn mb-3"> <img src={apple} /> {t('login_with_apple')}</button>
              <button className="facebook_login_btn mb-3"> <img src={facebook} /> {t('login_with_facebook')}</button>
            </Col>
            <Col lg={4} md={3} xs={12}> </Col>
          </Row>
          <Row>
            <Col lg={4} md={3} xs={12}></Col>
            <Col lg={4} md={6} xs={12}>
              <div className="both_side_border">{t('or')}</div>
            </Col>
            <Col lg={4} md={3} xs={12}> </Col>
          </Row> */}
          <Row>
            <Col lg={4} md={3} xs={12}></Col>
            <Col lg={4} md={6} xs={12}>
              <div className='d-flex flex-column justify-content-center align-items-center '>
                <button className="border_btn mt-3 mb-5" onClick={(e)=> onNavigatehandler(e, '/email-input')}>{t('login_with_email')}</button>
                <p className="question_text">{t('is_not_registered_yet')}</p>
                <button className="underline_btn" onClick={(e)=> onNavigatehandler(e, '/registration')}>{t('create_an_account')}</button>
              </div>
            </Col>
            <Col lg={4} md={3} xs={12}> </Col>
          </Row>
        </Container>
      </>
  );
};

export default LoginPage;