import { RESET_PASSWORD, RESET_PASSWORD_SUCCESS } from "./actionsTypes"

export const resetPwd = (email: any ,token:any , password:any, navigate: any) => {
    return {
        type: RESET_PASSWORD,
        payload: {email , token ,password, navigate}
   };
};
export const resetPwdSuccess = (message: string) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: message
    };
}