/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { Container, Card, Badge, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Stepper, Step } from 'react-form-stepper';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getBnsApi, get } from 'helpers/api_helpers';
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { PRIVATE_SELLER_API } from "constants/apiEndPoints/bnsPrivateSeller/privateSellerEndpoints";
import {addToPrivateSeller} from "../store/auth/login/actions";

// Start import helpers
import { addToPayoutList, clearPayout } from "store/payout/actions";
import { stepperStyleConfig, stepperConnectorStyleConfig, calculatePercentage } from 'helpers/common_helper';
import {
    bestsecretLogoImg,
    pngfindLogoImg,
    lbogoImg,
} from '../assets/image/image';

// Start import components
import Prompt from 'components/common/Prompt';
import ShippingPage from './ShippingPage';
import ConfirmRequestPage from "./ConfirmRequestPage";
import { toast } from "react-toastify";
import { toastValues } from "common/toastValues";
import { getPrefixUrl } from "helpers/common_helper";
import { COUPON_API } from "constants/apiEndPoints/couponsEndpoints";
const ibantools = require('ibantools');

const PayoutPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { state } = useLocation();
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);

    const [ user, setUser] = useState<any>({});
    const [paymentType, setPaymentType] = useState<string>("bank");
    const [accountName, setAccountName] = useState<string>("");
    const [accountNumber, setAccountNumber] = useState<string>("");
    const [bankName, setBankName] = useState<string>("");
    const [isShowBankName, setIsShowBankName] = useState<boolean>(true);
    const [bankSwift, setBankSwift] = useState<string>("");
    const [isShowBankSwift, setIsShowBankSwift] = useState<boolean>(true);
    const [partnerId, setPartnerId] = useState<any>(null);
    const [coupons, setCoupons] = useState<any>([]);
    const [coupon, setCoupon] = useState<any>({});
    const [activeStep, setActiveStep] = useState<number>(0);
    const [articles, setArticles] = useState<any>([]);
    const [isCheckFormValidation, setIsCheckFormValidation] = useState<boolean>(false);

    useEffect(() => {
        setUser(authUser);
        fetchPrivateSeller();
        fetchCoupons();

        // set partner information
        if(state?.partnerId && state?.partnerCoupon) {
            setPartnerId(state?.partnerId);
            setCoupon(state?.partnerCoupon);
            setPaymentType('coupon');
        }
    }, []);

    useEffect(() => {
        if(!state) {
            navigate(`${getPrefixUrl()}/`);
        } else {
            fetchBnsRequest();
        }
    }, []);


    // Function to compare 'created_at' values
    const compareCreatedDates = (a:any, b:any) => {
        return (new Date(b.created_at) as any) - (new Date(a.created_at) as any);
    };

    const fetchPrivateSeller = async () => {
        let params = "";
        
        if(authUser?.email) {
            params += `&filter[email]=${authUser.email}`;
        }

        // Address data Private Seller api server
        const response = await getBnsApi(PRIVATE_SELLER_API.search(params));
        const privateSellers = response?.private_sellers || [];
        let privateSeller = null;

        if(privateSellers?.length) {
            // Get the latest item based on 'created_at'
            privateSeller = privateSellers.reduce((latest:any, currentItem:any) => {
                if (!latest || compareCreatedDates(currentItem, latest) > 0) {
                    return currentItem;
                }
                return latest;
            }, null);
            
            //Set exist value of private seller bank_name
            if(privateSeller?.bank_name) {
                setBankName(privateSeller.bank_name);
                setIsShowBankName(false);
            }

            //Set exist value of private seller bank_swift
            if(privateSeller?.bank_swift) {
                setBankSwift(privateSeller.bank_swift);
                setIsShowBankSwift(false);
            }
        }
        
        dispatch(addToPrivateSeller(privateSeller));
    };

    const fetchBnsRequest= async () => {
        // setIsLoading(true);
        try {
            const { data } = await getBnsApi(REQUEST_BNS_API.fetch_request_by_id(state));
            const filteredArticles = data.articles.filter(
                (article:any) => article.state === 'confirmed');

            setArticles(filteredArticles || []);
            // setIsLoading(false);

        } catch (error) {
            // setIsLoading(false);
            toast.error("Some things went wrong!", toastValues);
        }
    };

    const fetchCoupons= async () => {
        // setIsLoading(true);
        try {
            const response:any = await get(COUPON_API.fetch_all());
            setCoupons(response);
        } catch (error) {
            // setIsLoading(false);
            toast.error("Some things went wrong!", toastValues);
        }
    };

    const totalCount = () => {
        let totalPrice:any = 0;
        articles.forEach((data:any) => {
            totalPrice += parseFloat(data.purchase_price)
        });
        
        //If payment type is coupon
        //Calculation and add benifit by coupon code
        if(paymentType === 'coupon' && coupon.code) {
          const couponPrice = calculatePercentage(totalPrice, coupon.value * 1, coupon.value_type);
          totalPrice = totalPrice + couponPrice;
        }
        const result = totalPrice.toFixed(2);
        return result || 0;
    }

    const onPaymentTypeHandler = (type:string) => {
        setPaymentType(type);
        if(type === 'coupon') {
            setCoupon({});
        }
    };

    const addPayout = () => {
        if(paymentType === "bank") {
            if(isValidTransactionForm()) {
                sendPayoutData();
            } else {
                if(isCheckFormValidation) setIsCheckFormValidation(false);
                setTimeout(()=>{
                    setIsCheckFormValidation(true);
                })
            }
        
        } else if(paymentType === "coupon") {
            if(!coupon?.id) {
                toast.error("Please, Select a coupon!", toastValues);
            } else {
                sendPayoutData();
            }
        }
    };

    const sendPayoutData = () => {
        const payout = {
            userId: user.id,
            addressId:"",
            refId:"",
            status: "pending",
            paymentType,
            accountName,
            accountNumber,
            bankName,
            bankSwift,
        };

        dispatch(clearPayout());
        dispatch(addToPayoutList(payout));
        setActiveStep(1);
        // navigate("/shipping");
    }

    const isValidTransactionForm = () => {    
        return !!(accountName && accountNumber && bankName && bankSwift && isValidBankIBAN());
    };

    const isValidBankIBAN = () => {  
        return ibantools.isValidIBAN(accountNumber.replaceAll(' ', ''));
    };

    const getCouponLogo = (coupon:any) => { 
        let logo = '';
        if(coupon?.name === 'Breuninger-Online') logo = lbogoImg;
        return logo;
    };

    const onChangeCoupon = (coupon:any, e:any) => { 
       setCoupon(coupon);
    };

    const stepsList = [
        { label: t('payout'), position: 0, classes:"payout-step" },
        { label: t('shipping'), position: 1, classes:"shipping-step" },
        { label: t('confirm'), position: 2, classes:"confirm-step" }
    ];
    // End events handlers

    return (
        <>
            <Container fluid className="background-white container-min-height">
                <Card.Title className="page-title text-uppercase"> {t('CHOOSE_PAYOUT')}</Card.Title>

                <Stepper activeStep={activeStep}
                         className="request-create-stepper"
                         connectorStateColors={true}
                         styleConfig={stepperStyleConfig}
                         connectorStyleConfig={stepperConnectorStyleConfig}
                >
                    {
                        stepsList.map((stepItem:any, index:number)=> {
                            return ( <Step key={`stepHeader_${index}`} className={`${stepItem?.classes || ''}`} label={stepItem.label} /> )
                        })
                    }
                </Stepper>

                <div className="stepper-container-box">
                    <div className="stepper-content">
                        {/* Start payout items field */}
                        {
                            !!(activeStep === 0)?
                                <>
                                    <Row>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                        <Col lg={6} md={6} xs={12}>
                                            <div className="text-center pb-5"> {t('payout_details')}</div>
                                            <div className="payout-list">
                                                <div className="mx-auto payout-options justify-content-center">

                                                    <Card className={`text-center payout-item ${paymentType === 'coupon'? 'active': ''}`} onClick={() => onPaymentTypeHandler("coupon")}>
                                                        <Card.Body>
                                                            <div className="py-3">
                                                                <Card.Title>
                                                                    <i className="fa-solid fa fa-desktop"></i>
                                                                    {paymentType === 'coupon'? <Badge className="default-badge checked-icon"><i className="fa fa-check"></i></Badge> : null}
                                                                </Card.Title>
                                                                <Card.Text className="text-uppercase">{t('coupon')}</Card.Text>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>

                                                    <Card className={`text-center payout-item ${paymentType === 'bank'? 'active': ''}`} onClick={() => onPaymentTypeHandler("bank")}>
                                                        <Card.Body>
                                                            <div className="py-3">
                                                                <Card.Title>
                                                                    <i className="fa-solid fa fa-building"></i>
                                                                    {paymentType === 'bank'? <Badge className="default-badge checked-icon"><i className="fa fa-check"></i></Badge> : null}
                                                                </Card.Title>
                                                                <Card.Text className="text-uppercase">{t('transfer')}</Card.Text>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg={3} md={3} xs={12}> </Col>
                                        <Col lg={6} md={6} xs={12}>
                                            {paymentType === 'coupon'? 
                                                // Start coupon list
                                                <div className="text-center">
                                                    <strong>{t('option voucher')}</strong>
                                                    <p>{t('option voucher description.option voucher description_one')}
                                                        <b> {t('option voucher description.option voucher description_two')}</b>{t('option voucher description.option voucher description_three')}
                                                    </p>
                                                    <p>{t('following vouchers')}</p>
                                                    <div className="voucher-box">
                                                        {
                                                            coupons.map((couponInfo:any)=>{
                                                               return (
                                                                <Col key={`coupons_${couponInfo.code}`} className="mb-4 d-flex justify-content-center" lg={3} md={4} xs={6}>
                                                                    <Form.Check className="coupon-card" type="radio" id={`coupon${couponInfo.code}`} >
                                                                        <Form.Check.Input type="radio"
                                                                            className="bns-checkbox bns-card round"
                                                                            name="coupon"
                                                                            checked={couponInfo.code === coupon.code}
                                                                            onChange={(e) => onChangeCoupon(couponInfo, e)}
                                                                        />
                                                                        <Form.Check.Label>
                                                                            <Card className="coupon-card-item">
                                                                                <div className="coupon-card-item-content">
                                                                                    <img src={getCouponLogo(couponInfo)} title={couponInfo.name}  />
                                                                                    {/* <p className="text-uppercase">{majorCategoryItem.name}</p> */}
                                                                                </div>
                                                                            </Card>
                                                                        </Form.Check.Label>
                                                                    </Form.Check>
                                                                </Col>
                                                                )
                                                            })
                                                        }
                                                        {/* <div className="partner_logo">
                                                            <img src={bestsecretLogoImg} />
                                                        </div>
                                                        <div className="partner_logo">
                                                            <img src={lbogoImg} />
                                                        </div> */}
                                                        </div>
                                                    </div>:<>

                                                 <div className={`mb-3 ${isCheckFormValidation && !accountName?'invalid':''}`}>
                                                    <Form.Group controlId="formBankAccountName">
                                                        <Form.Control type="text" placeholder="Erika Mustermann" defaultValue={accountName} onChange={(e)=>setAccountName(e.target.value)}/>
                                                    </Form.Group>
                                                    <small className="invalid-text">{t('form.invalid_message')}</small>
                                                </div>

                                                <div className={`mb-3 ${isCheckFormValidation && (!accountNumber || !isValidBankIBAN())?'invalid':''}`}>
                                                    <Form.Group controlId="formBankAccountIban">
                                                        <Form.Control type="text" placeholder="DE89 3704 0044 0532 0130 00" defaultValue={accountNumber} onChange={(e)=>setAccountNumber(e.target.value)} />
                                                    </Form.Group>
                                                    <small className="invalid-text">
                                                        { `${!accountNumber? t('form.invalid_message'):''} ${!isValidBankIBAN()? t('form.invalid_iban_message'):'' }` }
                                                    </small>
                                                </div>
                                                
                                                {
                                                    isShowBankName?  
                                                    <div className={`mb-3 ${isCheckFormValidation && !bankName?'invalid':''}`}>
                                                        <Form.Group controlId="formBankName">
                                                            <Form.Control type="text" placeholder="Bank Name" defaultValue={bankName} onChange={(e)=>setBankName(e.target.value)} />
                                                        </Form.Group>
                                                        <small className="invalid-text">{t('form.invalid_message')}</small>
                                                    </div>: <></>
                                                }

                                                {
                                                    isShowBankSwift?  <div className={`mb-3 ${isCheckFormValidation && !bankSwift?'invalid':''}`}>
                                                        <Form.Group controlId="formBankSwift">
                                                            <Form.Control type="text" placeholder="SWIFT / BIC" defaultValue={bankSwift} onChange={(e)=>setBankSwift(e.target.value)} />
                                                        </Form.Group>
                                                        <small className="invalid-text">{t('form.invalid_message')}</small>
                                                    </div>: <></>
                                                }

                                               
                                            </>}
                                        </Col>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                    </Row>

                                    <Row className="pb-4">
                                        <Col lg={3} md={3} xs={12}> </Col>
                                        <Col lg={6} md={6} xs={12}>
                                            <Card>
                                                <Card.Body className="text-center">
                                                    <Card.Title className="page-title pb-0 text-uppercase mb-0"> {t('our_range')}</Card.Title>
                                                    {/* <Card.Text className="text-success mb-1">{t('10% bonus text')}</Card.Text> */}
                                                    <h2 className="text-success fw-bold mb-3">{totalCount()} €</h2>
                                                    <Button className="round-btn mb-4" variant="dark" onClick={() => addPayout()} >{t('continue_sender_address')} </Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                    </Row>
                                </>:<></>
                        }

                        {/* Start shipping items field */}
                        {
                            activeStep === 1? <>
                                <ShippingPage goBack={(setActiveStep)} goNext={(setActiveStep)} request={state} coupon={coupon} />
                            </>:<></>
                        }

                        {/* Start payout overview items field */}
                        {
                            activeStep === 2? <>
                                <ConfirmRequestPage goBack={(setActiveStep)} goNext={(setActiveStep)} request={state} coupon={coupon} />
                            </>:<></>
                        }
                    </div>
                </div>
            </Container>

            {/* Start load components  */}
            <Prompt />            
        </>
    );
};

export default PayoutPage;
